import React, { useState, useMemo } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Polyline,
  Marker,
} from "@react-google-maps/api";
import env from "@beam-australia/react-env";
import iconMapOrigen from "../../assets/iconMap_origen.png";
import iconMapPos from "../../assets/iconMap_pos.png";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const Map = ({ markers = [] }) => {
  const [gMap, setGMap] = useState();

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: env("GOOGLE_MAPS_API_KEY"),
    libraries: ["geometry", "drawing"],
  });

  const options = useMemo(
    () => ({
      mapId: env("GOOGLE_MAPS_ID"),
      disableDefaultUI: true,
    }),
    []
  );

  return (
    isLoaded && (
      <GoogleMap
        onLoad={(m) => {
          setGMap(m);
        }}
        options={options}
        mapContainerStyle={containerStyle}
        center={
          markers?.length > 0 && {
            lat: markers[markers.length - 1].latitude,
            lng: markers[markers.length - 1].longitude,
          }
        }
        zoom={12} // Puedes ajustar este valor según lo que necesites
      >
        <Polyline
          options={{
            path: markers.map((m) => ({ lat: m.latitude, lng: m.longitude })),
            strokeColor: "#1976DE",
            strokeWeight: gMap?.getZoom() && gMap?.getZoom() / 1.8,
            strokeOpacity: 0.6,
          }}
        />

        <Marker
          key="origin"
          position={{
            lat: markers[0]?.latitude,
            lng: markers[0]?.longitude,
          }}
          icon={{
            url: iconMapOrigen,
            scaledSize: { width: 25, height: 25 },
            origin: { x: 0, y: 0 },
            anchor: { x: 15, y: 15 },
          }}
        />
        <Marker
          key="current"
          position={{
            lat: markers[markers.length - 1]?.latitude,
            lng: markers[markers.length - 1]?.longitude,
          }}
          icon={{
            url: iconMapPos,
            scaledSize: { width: 25, height: 25 },
            origin: { x: 0, y: 0 },
            anchor: { x: 15, y: 15 },
          }}
        />
      </GoogleMap>
    )
  );
};

export default Map;
