import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Chips } from "primereact/chips";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

import "react-toastify/dist/ReactToastify.css";
import "../../crud.css";

const PurchasersForm = ({ settings }) => {
  const { t } = useTranslation();

  const isEditMode = settings.formMode === "UPDATE";
  const isDeleteMode = settings.formMode === "DELETE";

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      active: true,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const formModeConfig = {};

  if (isEditMode) {
    formModeConfig.SubmitButtonText = t("buttons.update");
    formModeConfig.SubmitButtonTextWait = `${t("buttons.updating")}...`;
    formModeConfig.serverAction = async (data) => {
      data.emails = data.emails.toString();
      await settings.CRUDService.updateEntity(data);
    };
  } else if (isDeleteMode) {
    formModeConfig.SubmitButtonText = t("buttons.delete");
    formModeConfig.SubmitButtonTextWait = `${t("buttons.deleting")}...`;
    formModeConfig.serverAction = async () => {
      await settings.CRUDService.deleteEntity(settings.entityId);
    };
  } else {
    formModeConfig.SubmitButtonText = t("buttons.create");
    formModeConfig.SubmitButtonTextWait = `${t("buttons.creating")}...`;
    formModeConfig.serverAction = async (data) => {
      data.emails = data.emails.toString();
      await settings.CRUDService.createEntity(data);
    };
  }

  useEffect(() => {
    if (isEditMode || isDeleteMode) {
      const fetchCrop = async () => {
        try {
          const data = await settings.CRUDService.getEntity(settings.entityId);
          data.emails = data.emails.split(",");

          for (const key in data) {
            setValue(key, data[key]);
          }
        } catch (error) {
          console.error(t("forms.messages.operation.error"), error);
        }
      };

      fetchCrop();
    }
  }, [settings.entityId, setValue, settings.formMode]);

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      await formModeConfig.serverAction(data);

      settings.hideDialog();

      settings.toast.current.show({
        severity: "success",
        summary: t("forms.messages.titles.success"),
        detail: t("forms.messages.operation.success"),
        life: 3000,
      });
    } catch (error) {
      settings.toast.current.show({
        severity: "error",
        summary: t("forms.messages.titles.error"),
        detail: t("forms.messages.operation.error"),
        life: 3000,
      });

      setIsLoading(false);
    }
  };

  const getFormErrorMessage = (error) => {
    return error && <small className="p-error">{error.message}</small>;
  };

  return (
    <React.Fragment>
      <div className="flex justify-content-center">
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <div className="field">
            <Controller
              name="name"
              control={control}
              rules={{
                required: t("forms.validation.required", {
                  entity: t("entities.purchasers.name"),
                }),
                maxLength: {
                  value: 100,
                  message: t("forms.validation.maxLength", {
                    entity: t("entities.purchasers.name"),
                    maxLength: 100,
                  }),
                },
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <div className="flex justify-content-between">
                    <label htmlFor="name">
                      {t("entities.purchasers.name")}
                    </label>
                    <div>{getFormErrorMessage(errors.name)}</div>
                  </div>
                  <div className="form-crud">
                    <InputText
                      id={field.name}
                      {...field}
                      value={field.value || ""}
                      maxLength={100}
                      className={[
                        "form-control",
                        classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                      ]}
                      disabled={settings.formMode === "DELETE"}
                    />
                  </div>
                </React.Fragment>
              )}
            />
          </div>

          <div className="field">
            <Controller
              name="emails"
              control={control}
              rules={{
                required: t("forms.validation.required", {
                  entity: t("entities.purchasers.emails"),
                }),
                maxLength: {
                  value: 500,
                  message: t("forms.validation.maxLength", {
                    entity: t("entities.purchasers.emails"),
                    maxLength: 500,
                  }),
                },
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <div className="flex justify-content-between">
                    <label htmlFor="emails">
                      {`${t(
                        "entities.purchasers.emails"
                      )} - (Usar Enter para agregar mails)`}
                    </label>
                    <div>{getFormErrorMessage(errors.emails)}</div>
                  </div>
                  <div className="form-crud">
                    <Chips
                      separator=";"
                      id="emails"
                      {...field}
                      value={field.value || ""}
                      maxLength={100}
                      className={[
                        classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                      ]}
                      disabled={settings.formMode === "DELETE"}
                      onChange={(e) => field.onChange(e.value)}
                    />
                  </div>
                </React.Fragment>
              )}
            />
          </div>

          <div className="field">
            <Controller
              name="notes"
              control={control}
              rules={{
                maxLength: {
                  value: 500,
                  message: t("forms.validation.maxLength", {
                    entity: t("entities.purchasers.notes"),
                    maxLength: 500,
                  }),
                },
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <div className="flex justify-content-between">
                    <label htmlFor="notes">
                      {t("entities.purchasers.notes")}
                    </label>
                    <div>{getFormErrorMessage(errors.notes)}</div>
                  </div>
                  <div className="form-crud">
                    <InputTextarea
                      id="notes"
                      {...field}
                      value={field.value ? field.value : ""}
                      rows={3}
                      maxLength={500}
                      className={[
                        "form-control",
                        classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                      ]}
                      disabled={settings.formMode === "DELETE"}
                    />
                  </div>
                </React.Fragment>
              )}
            />
          </div>

          <div className="field">
            <div className="field-checkbox">
              <Controller
                name="active"
                control={control}
                render={({ field, fieldState }) => (
                  <Checkbox
                    inputId={field.name}
                    onChange={(e) => field.onChange(e.checked)}
                    checked={field.value}
                    className={classNames({ "p-invalid": fieldState.invalid })}
                    disabled={settings.formMode === "DELETE"}
                  />
                )}
              />
              <label
                htmlFor="accept"
                className={classNames({ "p-error": errors.active })}
              >
                {t("entities.purchasers.active")}
              </label>
            </div>
          </div>

          <div className="flex justify-content-between">
            <Button
              label={t("buttons.cancel")}
              icon="pi pi-times"
              className="p-button-text btn-secondary-crud"
              onClick={() => settings.hideDialog("CANCEL")}
              type="button"
              disabled={isLoading}
            />
            <Button
              label={
                isLoading
                  ? formModeConfig.SubmitButtonTextWait
                  : formModeConfig.SubmitButtonText
              }
              icon="pi pi-check"
              className=" btn btn-primary btn-primary-crud"
              type="submit"
              disabled={isLoading}
            />
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default PurchasersForm;
