import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import LoginPage from "../views/public/login/login";

import Logout from "../components/logout";
import Crops from "../views/private/configurations/crops/crops";
import Destinations from "../views/private/configurations/destinations/destinations";
import Fields from "../views/private/configurations/fields/fields";
import Harvests from "../views/private/harvests/harvests";
import LogisticsProviders from "../views/private/configurations/logisticsProviders/logisticsProviders";
import Purchasers from "../views/private/configurations/purchasers/purchasers";

import Client from "../views/private/client/client";

import Administrators from "../views/private/users/administrators/administrators";
import Hoppers from "../views/private/users/hoppers/hoppers";
import Truckers from "../views/private/users/truckers/truckers";
import VouchersHistory from "../views/private/vouchers/vouchersHistory";
import VouchersActives from "../views/private/vouchers/vouchersActives";
import VouchersCancel from "../views/private/vouchers/vouchersCancel";
const ProtectedApp = () => {
  //const navigate = useNavigate();

  // useEffect(() => {
  //   try {
  //     const token = localStorage.getItem("token");
  //     // Aquí puedes agregar la lógica para comprobar si el usuario está autenticado
  //     // Si no lo está, redirigir al usuario a la página de inicio de sesión
  //     if (!token) {

  //       navigate("/");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     navigate("/");
  //   }
  // }, [navigate]); // Agrega 'navigate' como dependencia para evitar problemas de actualización

  return (
    <>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/harvests" element={<Harvests />} />
        <Route path="/vouchershistory" element={<VouchersHistory />} />
        <Route path="/vouchersactives" element={<VouchersActives />} />
        <Route path="/voucherscancel" element={<VouchersCancel />} />
        <Route path="/users/administrators" element={<Administrators />} />
        <Route path="/users/hoppers" element={<Hoppers />} />
        <Route path="/users/truckers" element={<Truckers />} />
        <Route path="/configurations/destinations" element={<Destinations />} />
        <Route path="/configurations/fields" element={<Fields />} />
        <Route path="/configurations/crops" element={<Crops />} />
        <Route
          path="/configurations/logisticsproviders"
          element={<LogisticsProviders />}
        />
        <Route path="/configurations/purchasers" element={<Purchasers />} />
        <Route path="/client/client" element={<Client />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </>
  );
};

export default ProtectedApp;
