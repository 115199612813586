
import moment from "moment";

const isValidDate = (date) => {
    const _date = moment(date);

    return _date.isValid() && _date.isAfter(moment("2000-01-01"));
}

const dateTemplateForList = (rowData, column) => {
    const date = moment(rowData[column.field]);
    return isValidDate(date)
        ? moment(date).format("YYYY-MM-DD")
        : "";
};

const dateTemplate = (date) => {
    const _date = moment(date);
    return isValidDate(_date)
        ? moment(_date).format("YYYY-MM-DD")
        : "";
};

export default {
    isValidDate,
    dateTemplateForList,
    dateTemplate
}

// const dateTemplate = (rowData, column) => {
//     const date = moment(rowData[column.field]);
//     return isValidDate(rowData[column.field]) == "0001-01-01T00:00:00"
//       ? ""
//       : moment(date).format("YYYY-MM-DD");
//   };