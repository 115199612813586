import React, { useState, useEffect, useRef, useCallback } from "react";
import env from "@beam-australia/react-env";

import useApi from "../../../hooks/useApi";

import { useNavigate } from "react-router-dom";

import { BlockUI } from "primereact/blockui";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import { useTranslation } from "react-i18next";

import { useDebounce } from "../../../hooks/useDebounce";
import useCRUDService from "../../../hooks/useCRUDService";

import Searcher from "../../../components/searcher/searcher";
import Layout from "../../../layout/layout";
import DateTimeHelper from "../../../utils/dateTimeHelper";
import VoucherDetail from "./vouchersDetail";
import Header from "../../../layout/header";

import useSkeleton from "../../../hooks/useSkeleton";

const VouchersCancel = () => {
  const s = useSkeleton("commons");
  const api = useApi();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const CRUDService = useCRUDService("vouchers");

  const [entities, setEntities] = useState(null);
  const [detailDialog, setDetailDialog] = useState(false);

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");

  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedHarvest, setSelectedHarvest] = useState({
    name: "",
  });

  const searchInput = useRef(null);

  // Inicializamos el debounce para la búsqueda
  const debouncedSearchTerm = useDebounce(globalFilter, 2000);

  const toast = useRef(null);
  const dt = useRef(null);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    try {
      const columns = JSON.stringify([
        {
          name: "CreatedDate",
          type: "date",
        },
        {
          name: "purchaser_Name",
          type: "string",
        },
        {
          name: "crop_Name",
          type: "string",
        },
        {
          name: "weight",
          type: "number",
        },
        {
          name: "finalWeight",
          type: "number",
        },
      ]);

      const responseVouchersPaged = await api.get(`/vouchers/cancel/paged`, {
        params: {
          columns: columns,
          page: first,
          pageSize: rows,
          sort: sortField ?? "createdDate",
          order: sortOrder >= 0 ? "DESC" : "ASC",
          globalFilter: debouncedSearchTerm,
        },
      });

      setTotalRecords(responseVouchersPaged.data.totalRows);
      setEntities(responseVouchersPaged.data.rows);
    } catch (error) {
      console.log(error);
      if (error.callback) {
        error.callback();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: t("errors.general"),
          life: 3000,
        });
      }
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    first,
    rows,
    sortField,
    sortOrder,
    debouncedSearchTerm,
    CRUDService,
    selectedHarvest,
  ]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [first, rows, sortField, sortOrder, debouncedSearchTerm, selectedHarvest]);

  // const getClosedHarvests = async (
  //   first,
  //   rows,
  //   sortField,
  //   sortOrder,
  //   debouncedSearchTerm
  // ) => {
  //   return await api.get(`/harvests/closed/paged`, {
  //     params: {
  //       page: first,
  //       pageSize: rows,
  //       sort: sortField ?? "CreatedDate",
  //       order: sortOrder >= 0 ? "DESC" : "ASC",
  //       globalFilter: debouncedSearchTerm,
  //     },
  //   });
  // };
  const openDetailDialog = async (formMode, selectedCrop) => {
    setFormSettings({
      ...formSettings,
      entityId: selectedCrop?.id,
    });
    setDetailDialog(true);
  };

  const hideDetailDialog = (action) => {
    setDetailDialog(false);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const [formSettings, setFormSettings] = useState({
    entityId: -1,
    formMode: "",
    toast: toast,
    CRUDService: CRUDService,
  });

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => openDetailDialog("UPDATE", rowData)}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="table-header">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          ref={searchInput}
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder={`${t("DataTable.Search")}...`}
        />
      </span>
      <Button
        outlined
        label={t("DataTable.Export")}
        icon="pi pi-upload"
        className="p-button-secondary"
        onClick={exportCSV}
        style={{
          marginLeft: "20px",
        }}
      />
    </div>
  );

  return (
    <BlockUI blocked={isLoading}>
      <Layout
        header={
          <Header
            icon={<i className="fa-solid fa-file-circle-xmark"></i>}
            title={t("entities.vouchers.cancelledVouchers")}
          />
        }
      >
        <Toast ref={toast} />

        <div className={s.get("dataTableContainer")}>
          <DataTable
            onPage={(e) => {
              setFirst(e.first);
              setRows(e.rows);
            }}
            onSort={(e) => {
              setSortField(e.sortField);
              setSortOrder(e.sortOrder);
            }}
            sortField={sortField}
            sortOrder={sortOrder}
            ref={dt}
            value={entities}
            lazy
            paginator
            first={first}
            rows={rows}
            totalRecords={totalRecords}
            resizableColumns
            columnResizeMode="fit"
            showGridlines
            dataKey="id"
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={`${t(
              "DataTable.currentPageReportTemplate"
            )} ${t("Vouchers")}`}
            globalFilter={globalFilter}
            header={header}
          >
            <Column
              field="createdDate"
              header={t("entities.vouchers.createdDate")}
              body={DateTimeHelper.dateTemplateForList}
              style={{ textAlign: "center" }}
              sortable
            ></Column>
            <Column
              field="purchaser_Name"
              header={t("entities.vouchers.purchaser_Name")}
              sortable
            ></Column>
            <Column
              field="crop_Name"
              header={t("entities.vouchers.crop_Name")}
              sortable
            ></Column>
            <Column
              field="weight"
              header={t("entities.vouchers.weight")}
              style={{ textAlign: "right" }}
              sortable
            ></Column>
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ width: "8rem", textAlign: "center" }}
            ></Column>
          </DataTable>

          <React.Fragment>
            <Dialog
              visible={detailDialog}
              style={{ width: "90%" }}
              header={`${t("entities.vouchers.singular")}`}
              modal
              className="p-fluid"
              onHide={hideDetailDialog}
            >
              <VoucherDetail settings={formSettings} />
            </Dialog>
          </React.Fragment>
        </div>
      </Layout>
    </BlockUI>
  );
};

export default VouchersCancel;
