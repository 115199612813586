import useApi from "./useApi";

const useCRUDService = (_entityName) => {
  const api = useApi();

  const entityName = _entityName;

  const getEntities = async (columns, page, pageSize, sort, order, globalFilter, prefix, posfix) => {
    let uri = `/*PREFIX*/${entityName}/paged/*POSFIX*/`;

    uri = prefix ?
      uri.replace("*PREFIX*", prefix) :
      uri.replace("/*PREFIX*", "");

    uri = posfix ?
      uri.replace("*POSFIX*", posfix) :
      uri.replace("/*POSFIX*", "");

    const response = await api.get(uri, {
      params: {
        columns,
        page,
        pageSize,
        sort,
        order,
        globalFilter,
      },
    });

    return response;
  };

  const getEntity = async (id, prefix, posfix) => {
    let uri = `/*PREFIX*/${entityName}/*POSFIX*/${id}`;

    uri = prefix ?
      uri.replace("*PREFIX*", prefix) :
      uri.replace("/*PREFIX*", "");

    uri = posfix ?
      uri.replace("*POSFIX*", posfix) :
      uri.replace("/*POSFIX*", "");

    const response = await api.get(uri);

    return response.data;
  };

  const createEntity = async (entity, prefix, posfix) => {
    let uri = `/*PREFIX*/${entityName}/*POSFIX*`;

    uri = prefix ?
      uri.replace("*PREFIX*", prefix) :
      uri.replace("/*PREFIX*", "");

    uri = posfix ?
      uri.replace("*POSFIX*", posfix) :
      uri.replace("/*POSFIX*", "");

    await api.post(uri, entity);
  };

  const updateEntity = async (entity, prefix, posfix) => {
    let uri = `/*PREFIX*/${entityName}/*POSFIX*/${entity.id}`;

    uri = prefix ?
      uri.replace("*PREFIX*", prefix) :
      uri.replace("/*PREFIX*", "");

    uri = posfix ?
      uri.replace("*POSFIX*", posfix) :
      uri.replace("/*POSFIX*", "");

    await api.put(uri, entity);
  };

  const deleteEntity = async (id, prefix, posfix) => {
    let uri = `/*PREFIX*/${entityName}/*POSFIX*/${id}`;

    uri = prefix ?
      uri.replace("*PREFIX*", prefix) :
      uri.replace("/*PREFIX*", "");

    uri = posfix ?
      uri.replace("*POSFIX*", posfix) :
      uri.replace("/*POSFIX*", "");

    await api.delete(uri);
  };

  return {
    getEntities,
    getEntity,
    createEntity,
    updateEntity,
    deleteEntity
  }
};

export default useCRUDService;
