import React from "react";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import useSkeleton from "../hooks/useSkeleton";
import "../layout/layout.css";
const MenuItem = (props) => {
  const skeleton = useSkeleton("layout.menu.menuItems");

  return (
    <div className={skeleton.get("menuItem")}>
      <div className={`${skeleton.get("menuItem.option.icon")} icon`}>
        {props.icon}
      </div>
      <div className={skeleton.get("menuItem.option.text")}>
        <Link to={props.to}>
          <Button label={props.label} text className="p-button-label.menu" />
        </Link>
      </div>
    </div>
  );
};

export default MenuItem;
