import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useApi from "../hooks/useApi";
import env from "@beam-australia/react-env";

const Logout = () => {
  const api = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    const logout = async () => {
      const refreshToken = localStorage.getItem("refreshToken");

      await api.post(`/auth/logout`, {
        refreshToken,
      });
      // Eliminar el token y refreshToken del localStorage
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");

      // Navegar a la página de inicio de sesión
      navigate("/");
    };

    logout();
  }, [navigate]);

  return null;
};

export default Logout;
