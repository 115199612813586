import React, { useState } from "react";
import { FileUpload } from "primereact/fileupload";
import { Toast } from "primereact/toast"; // Componente de notificaciones

const ImageUpload = (props) => {
  const maxSizeImage = 1000000;
  const toast = React.createRef(); // referencia para el componente Toast

  const customBase64Uploader = async (event) => {
    // convert file to base64 encoded
    try {
      const file = event.files[0];
      if (file.size > maxSizeImage) {
        throw new Error("La imagen execede el tamaño máximo");
      }

      const reader = new FileReader();
      let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        props.setImage(reader.result);
        toast.current.show({
          severity: "success",
          summary: "Éxito",
          detail: "Imagen subida correctamente",
        });
      };
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
      });
    }
  };

  return (
    <div>
      <Toast ref={toast} />

      <FileUpload
        name="image"
        accept="image/*"
        maxFileSize={maxSizeImage}
        uploadLabel="Subir"
        chooseLabel="Seleccionar"
        cancelLabel="Cancelar"
        customUpload
        uploadHandler={customBase64Uploader}
      />
    </div>
  );
};

export default ImageUpload;
