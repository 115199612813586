import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import env from "@beam-australia/react-env";

import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { BlockUI } from "primereact/blockui";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

import ImageUpload from "../../../components/fileUpload/fileUpload";

import "react-toastify/dist/ReactToastify.css";
import "../crud.css";
import Layout from "../../../layout/layout";
import useApi from "../../../hooks/useApi";
import useCRUDService from "../../../hooks/useCRUDService";
import Header from "../../../layout/header";

const Profile = () => {
  const { t } = useTranslation();
  const api = useApi();
  const CRUDService = useCRUDService("clients");
  const toast = useRef(null);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      rut: "",
      address: "",
      phone: "",
      email: "",
      notes: "",
      active: true,
    },
  });

  const [imageSrc, setImageSrc] = React.useState(null);
  const [logo, setLogo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  let passwordMode = "HIDDEN"; // "UPDATE", "VISIBLE"

  const formModeConfig = {};

  passwordMode = "UPDATE";
  formModeConfig.SubmitButtonText = t("buttons.update");
  formModeConfig.SubmitButtonTextWait = `${t("buttons.updating")}...`;
  formModeConfig.serverAction = async (data) => {
    if (logo) {
      data.logo = logo;
    } else {
      data.logo = imageSrc;
    }

    // console.log(data.logo);
    await CRUDService.updateEntity(data);
  };

  useEffect(() => {
    const fetchCrop = async () => {
      try {
        const response = await api.get(`/clients/profile`);
        setImageSrc(response.data.logo);
        for (const key in response.data) {
          setValue(key, response.data[key]);
        }
      } catch (error) {
        console.error(t("forms.messages.operation.error"), error);
      }
    };

    fetchCrop();
  }, [setValue]);

  useEffect(() => {
    const fetchCrop = async () => {
      setImageSrc(logo);
    };
  }, [logo]);

  const handlePassword = (e) => {
    e.preventDefault();
    passwordMode = "VISIBLE";
  };

  const [isVisible, setIsVisible] = useState(passwordMode == "VISIBLE");

  const handleShow = (e) => {
    e.preventDefault();
    setIsVisible(!isVisible);
  };

  const handleHide = () => {
    setIsVisible(false);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      await formModeConfig.serverAction(data);
      toast.current.show({
        severity: "success",
        summary: t("forms.messages.titles.success"),
        detail: t("forms.messages.operation.success"),
        life: 3000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: t("forms.messages.titles.error"),
        detail: t("forms.messages.operation.error"),
        life: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getFormErrorMessage = (error) => {
    return error && <small className="p-error">{error.message}</small>;
  };

  const imageUploaded = (base64Image) => {
    setImageSrc(base64Image);
    setLogo(base64Image);
  };

  return (
    <BlockUI blocked={isLoading}>
      <Layout
        header={
          <Header
            icon={<i className="fa-regular fa-user"></i>}
            title={t("sitemap.profile")}
          />
        }
      >
        <Toast ref={toast} />
        <div className="flex flex-grow-1 p-5 md:min-w-max justify-content-center ">
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
            <div className="flex justify-content-between">
              <div className="field">
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    required: t("forms.validation.required", {
                      entity: t("entities.clients.name"),
                    }),
                    maxLength: {
                      value: 500,
                      message: t("forms.validation.maxLength", {
                        entity: t("entities.clients.name"),
                        maxLength: 500,
                      }),
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <React.Fragment>
                      <div className="flex justify-content-between">
                        <label htmlFor="name">
                          {t("entities.clients.name")}
                        </label>
                        <div>{getFormErrorMessage(errors.name)}</div>
                      </div>
                      <div className="form-crud">
                        <InputText
                          id={field.name}
                          {...field}
                          value={field.value || ""}
                          maxLength={100}
                          className={[
                            "form-control",
                            classNames({
                              "p-invalid": fieldState.invalid,
                            }),
                          ]}
                        />
                      </div>
                    </React.Fragment>
                  )}
                />
              </div>
              <div className="formHorizontalSeparation"></div>
              <div className="field">
                <Controller
                  name="rut"
                  control={control}
                  rules={{
                    required: t("forms.validation.required", {
                      entity: t("entities.clients.rut"),
                    }),
                  }}
                  render={({ field }) => (
                    <React.Fragment>
                      <div className="flex justify-content-between">
                        <label htmlFor="rut">{t("entities.clients.rut")}</label>
                        <div>{getFormErrorMessage(errors.rut)}</div>
                      </div>
                      <div className="form-crud">
                        <InputNumber
                          id={field.name}
                          value={field.value || 0}
                          onValueChange={(e) => field.onChange(e.value)}
                          useGrouping={false}
                          inputClassName={[
                            classNames({ "p-invalid": errors.rut }),
                          ]}
                        />
                      </div>
                    </React.Fragment>
                  )}
                />
              </div>
            </div>

            <div className="flex justify-content-between">
              <div className="field">
                <Controller
                  name="address"
                  control={control}
                  rules={{
                    required: t("forms.validation.required", {
                      entity: t("entities.clients.address"),
                    }),
                    maxLength: {
                      value: 250,
                      message: t("forms.validation.maxLength", {
                        entity: t("entities.clients.address"),
                        maxLength: 250,
                      }),
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <React.Fragment>
                      <div className="flex justify-content-between">
                        <label htmlFor="address">
                          {t("entities.clients.address")}
                        </label>
                        <div>{getFormErrorMessage(errors.address)}</div>
                      </div>
                      <div className="form-crud">
                        <InputText
                          id="address"
                          {...field}
                          value={field.value || ""}
                          maxLength={250}
                          className={[
                            "form-control",
                            classNames({
                              "p-invalid": fieldState.invalid,
                            }),
                          ]}
                        />
                      </div>
                    </React.Fragment>
                  )}
                />
              </div>
              <div className="formHorizontalSeparation"></div>
              <div className="field">
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    required: t("forms.validation.required", {
                      entity: t("entities.clients.phone"),
                    }),
                    maxLength: {
                      value: 14,
                      message: t("forms.validation.maxLength", {
                        entity: t("entities.clients.phone"),
                        maxLength: 14,
                      }),
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <React.Fragment>
                      <div className="flex justify-content-between">
                        <label htmlFor="phone">
                          {t("entities.clients.phone")}
                        </label>
                        <div>{getFormErrorMessage(errors.phone)}</div>
                      </div>
                      <div className="form-crud">
                        <InputText
                          id="phone"
                          {...field}
                          value={field.value || ""}
                          maxLength={14}
                          className={[
                            "form-control",
                            classNames({
                              "p-invalid": fieldState.invalid,
                            }),
                          ]}
                        />
                      </div>
                    </React.Fragment>
                  )}
                />
              </div>
            </div>

            <div className="flex justify-content-between">
              <div className="field">
                <Controller
                  name="city"
                  control={control}
                  rules={{
                    required: t("forms.validation.required", {
                      entity: t("entities.clients.city"),
                    }),
                    maxLength: {
                      value: 100,
                      message: t("forms.validation.maxLength", {
                        entity: t("entities.clients.city"),
                        maxLength: 100,
                      }),
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <React.Fragment>
                      <div className="flex justify-content-between">
                        <label htmlFor="city">
                          {t("entities.clients.city")}
                        </label>
                        <div>{getFormErrorMessage(errors.city)}</div>
                      </div>
                      <div className="form-crud">
                        <InputText
                          id="city"
                          {...field}
                          value={field.value || ""}
                          maxLength={100}
                          className={[
                            "form-control",
                            classNames({
                              "p-invalid": fieldState.invalid,
                            }),
                          ]}
                        />
                      </div>
                    </React.Fragment>
                  )}
                />
              </div>
              <div className="formHorizontalSeparation"></div>
              <div className="field">
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: t("forms.validation.required", {
                      entity: t("entities.purchasers.emails"),
                    }),
                    pattern: {
                      value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                      message: t("forms.validation.noValidEmail"),
                    },
                    maxLength: {
                      value: 100,
                      message: t("forms.validation.maxLength", {
                        entity: t("entities.clients.email"),
                        maxLength: 100,
                      }),
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <React.Fragment>
                      <div className="flex justify-content-between">
                        <label htmlFor="email">
                          {t("entities.clients.email")}
                        </label>
                        <div>{getFormErrorMessage(errors.email)}</div>
                      </div>
                      <div className="form-crud">
                        <InputText
                          id="email"
                          {...field}
                          value={field.value || ""}
                          maxLength={100}
                          className={[
                            "form-control",
                            classNames({
                              "p-invalid": fieldState.invalid,
                            }),
                          ]}
                          autoComplete="off"
                        />
                      </div>
                    </React.Fragment>
                  )}
                />
              </div>
            </div>

            <div className="flex justify-content-between align-content-end">
              <div className="field">
                <label htmlFor="logo">{t("entities.clients.logo")}</label>
                <div>
                  <ImageUpload setImage={imageUploaded} />
                </div>
              </div>
            </div>

            <div className="field">
              <Controller
                name="notes"
                control={control}
                rules={{
                  maxLength: {
                    value: 500,
                    message: t("forms.validation.maxLength", {
                      entity: t("entities.clients.notes"),
                      maxLength: 500,
                    }),
                  },
                }}
                render={({ field, fieldState }) => (
                  <React.Fragment>
                    <div className="flex justify-content-between">
                      <label htmlFor="notes">
                        {t("entities.clients.notes")}
                      </label>
                      <div>{getFormErrorMessage(errors.notes)}</div>
                    </div>
                    <div className="form-crud">
                      <InputTextarea
                        id="notes"
                        {...field}
                        value={field.value ? field.value : ""}
                        rows={3}
                        maxLength={500}
                        className={[
                          "form-control",
                          classNames({
                            "p-invalid": fieldState.invalid,
                          }),
                        ]}
                      />
                    </div>
                  </React.Fragment>
                )}
              />
            </div>
            <div className="field">
              <div className="field-checkbox">
                <Controller
                  name="active"
                  control={control}
                  render={({ field, fieldState }) => (
                    <Checkbox
                      inputId={field.name}
                      onChange={(e) => field.onChange(e.checked)}
                      checked={field.value}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                <label
                  htmlFor="accept"
                  className={classNames({ "p-error": errors.active })}
                >
                  {t("entities.clients.active")}
                </label>
              </div>
            </div>
            <div className="flex" style={{ float: "right" }}>
              <Button
                label={
                  isLoading
                    ? formModeConfig.SubmitButtonTextWait
                    : formModeConfig.SubmitButtonText
                }
                icon="pi pi-check"
                className=" btn btn-primary btn-primary-crud"
                type="submit"
                disabled={isLoading}
              />
            </div>
          </form>
        </div>
      </Layout>
    </BlockUI>
  );
};

export default Profile;
