import { useCallback, useEffect, useRef, useState } from 'react';

// Debounce para valores
export function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
}



export function useDebouncedCallback(callback, delay, deps = []) {
    const functionTimeoutHandler = useRef(null);
    const debouncedFunction = useCallback(callback, deps);

    useEffect(
        () => () => {
            clearTimeout(functionTimeoutHandler.current);
        },
        [] // Esta matriz de dependencias debe estar vacía para evitar ejecuciones múltiples
    );

    return (...args) => {
        clearTimeout(functionTimeoutHandler.current);
        functionTimeoutHandler.current = setTimeout(() => debouncedFunction(...args), delay);
    };
}
