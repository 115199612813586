import React, { useState, useEffect, useRef, useCallback } from "react";
import env from "@beam-australia/react-env";

import { BlockUI } from "primereact/blockui";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import { useTranslation } from "react-i18next";

import { useDebounce } from "../../../../hooks/useDebounce";
import useCRUDService from "../../../../hooks/useCRUDService";
import useApi from "../../../../hooks/useApi";
import ActiveInactive from "../../../../components/activeInactive/activeInactive";
import TruckersForm from "./truckersForm";
import Layout from "../../../../layout/layout";
import Header from "../../../../layout/header";
import useSkeleton from "../../../../hooks/useSkeleton";

const Truckers = () => {
  const s = useSkeleton("commons");
  const api = useApi();
  const { t } = useTranslation();
  const CRUDService = useCRUDService("truckers");

  const [entities, setEntities] = useState(null);
  const [formDialog, setFormDialog] = useState(false);

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");

  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const searchInput = useRef(null);

  // Inicializamos el debounce para la búsqueda
  const debouncedSearchTerm = useDebounce(globalFilter, 2000);

  const toast = useRef(null);
  const dt = useRef(null);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const columns = JSON.stringify([
        {
          name: "name",
          type: "string",
        },
        {
          name: "phone",
          type: "string",
        },
        {
          name: "notes",
          type: "string",
        },
      ]);

      const response = await CRUDService.getEntities(
        columns,
        first,
        rows,
        sortField ?? "name",
        sortOrder >= 0 ? "ASC" : "DESC",
        debouncedSearchTerm
      );

      setTotalRecords(response.data.totalRows);
      setEntities(response.data.rows);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: t("errors.general"),
        life: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  }, [first, rows, sortField, sortOrder, debouncedSearchTerm, CRUDService]);

  useEffect(() => {
    fetchData();
  }, [first, rows, sortField, sortOrder, debouncedSearchTerm]);
  const openFormDialog = async (formMode, selectedTrucker) => {
    setFormSettings({
      ...formSettings,
      formMode: formMode,
      entityId: selectedTrucker?.id,
    });

    setFormDialog(true);
  };

  const hideDialog = (action) => {
    if (action !== "CANCEL") {
      setFormDialog(false);
      // Restablece todos los filtros a sus valores predeterminados
      setFirst(0);
      setRows(10);
      setSortField(null);
      setSortOrder(null);
      setGlobalFilter("");
      // Restablece el valor del campo de entrada
      if (searchInput.current) {
        searchInput.current.value = "";
      }
      // Luego, realiza una nueva búsqueda con los filtros restablecidos
      fetchData();
    }
    setFormDialog(false);
  };

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const [formSettings, setFormSettings] = useState({
    entityId: -1,
    formMode: "",
    hideDialog: hideDialog,
    toast: toast,
    CRUDService: CRUDService,
  });

  const actions = (
    <React.Fragment>
      <Button
        label={t("DataTable.New")}
        icon="pi pi-plus"
        className="p-button-primary"
        onClick={() => openFormDialog("CREATE")}
      />
    </React.Fragment>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => openFormDialog("UPDATE", rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="  p-button-rounded p-button-danger"
          onClick={() => openFormDialog("DELETE", rowData)}
        />
      </React.Fragment>
    );
  };

  const activeInactiveItem = async (id, value) => {
    setIsLoading(true);

    try {
      const responseActiveToggle = await api.put(
        `/truckers/toggleactive/${id}`
      );

      const found = dt.current.props.value.find((element) => element.id == id);

      if (found) {
        found.active = responseActiveToggle.data.active;
      }

      toast.current.show({
        severity: "success",
        summary: t("forms.messages.titles.success"),
        detail: t("forms.messages.operation.success"),
        life: 3000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: t("forms.messages.titles.error"),
        detail: t("forms.messages.operation.error"),
        life: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const actionActive = (rowData) => {
    return (
      <ActiveInactive
        onChange={activeInactiveItem}
        itemId={rowData.id}
        value={rowData.active}
      />
    );
  };

  const header = (
    <div className="table-header">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          ref={searchInput}
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder={`${t("DataTable.Search")}...`}
        />
      </span>
      <Button
        outlined
        label={t("DataTable.Export")}
        icon="pi pi-upload"
        className="p-button-secondary"
        onClick={exportCSV}
        style={{
          marginLeft: "20px",
        }}
      />
    </div>
  );

  const getDialogTitle = () => {
    let action = "Add";

    if (formSettings.formMode == "DELETE") {
      action = "Delete";
    } else if (formSettings.formMode == "UPDATE") {
      action = "Edit";
    }

    return `${t(action)} ${t("entities.truckers.singular")}`;
  };

  return (
    <BlockUI blocked={isLoading}>
      <Layout
        header={
          <Header
            icon={<i className="fa-solid fa-truck"></i>}
            route={`${t("sitemap.configuration")}/${t("sitemap.truckers")}`}
            title={t("entities.truckers.plural")}
            actions={actions}
          />
        }
      >
        <Toast ref={toast} />

        <div className={s.get("dataTableContainer")}>
          <DataTable
            onPage={(e) => {
              setFirst(e.first);
              setRows(e.rows);
            }}
            onSort={(e) => {
              setSortField(e.sortField);
              setSortOrder(e.sortOrder);
            }}
            sortField={sortField}
            sortOrder={sortOrder}
            ref={dt}
            value={entities}
            lazy
            paginator
            first={first}
            rows={rows}
            totalRecords={totalRecords}
            resizableColumns
            columnResizeMode="fit"
            showGridlines
            dataKey="id"
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={`${t(
              "DataTable.currentPageReportTemplate"
            )} ${t("Truckers")}`}
            globalFilter={globalFilter}
            header={header}
          >
            <Column
              field="name"
              header={t("entities.truckers.name")}
              sortable
            ></Column>
            <Column
              field="phone"
              header={t("entities.truckers.phone")}
              sortable
            ></Column>
            <Column
              field="notes"
              header={t("entities.truckers.notes")}
            ></Column>
            <Column
              field="active"
              header={t("entities.destinations.active")}
              style={{ textAlign: "center" }}
              body={actionActive}
              exportable={false}
            ></Column>
            <Column
              body={actionBodyTemplate}
              exportable={false}
              style={{ width: "8rem", textAlign: "center" }}
            ></Column>
          </DataTable>
          <Dialog
            visible={formDialog}
            style={{ width: "600px" }}
            header={getDialogTitle()}
            modal
            className="p-fluid"
            onHide={hideDialog}
          >
            <TruckersForm settings={formSettings} />
          </Dialog>
        </div>
      </Layout>
    </BlockUI>
  );
};

export default Truckers;
