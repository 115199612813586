import React, { useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ProtectedApp from './utils/protectedApp';

const App = () => {

  return (
    <Router>
      <ProtectedApp />
    </Router >
  );
};

export default App;







