import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      Actions: "Actions",
      Active: "Active",
      Add: "Add",
      Add_record: "Add record",
      Administrator: "Administrator",
      Administrators: "Administrators",
      An_error_occurred_while_trying_to_perform_the_operation:
        "An error occurred while trying to perform the operation",
      and: "and",
      Back: "Back",
      characters: "characters",
      Configurations: "Configurations",
      Create: "Create",
      Created_date: "Created date",
      Crop: "Crop",
      Crops: "Crops",
      Delete: "Delete",
      Destinations: "Destinations",
      Destination: "Destination",
      Edit: "Edit",
      End_date: "End date",
      Fields: "Fields",
      Field: "Field",
      Go_to_page: "Go to page",
      Harvests: "Harvests",
      Harvest: "Harvest",
      Home: "Home",
      Hopper: "Hopper",
      Hoppers: "Hoppers",
      is_required: "is required",
      Latitude: "Latitude",
      Longitude: "Longitude",
      Login: "Login",
      Login_failed: "Login failed",
      Logistics_providers: "Logistics providers",
      Logistics_provider: "Logistics provider",
      Logout: "Logout",
      must_not_exceed: "must not exceed",
      Name: "Name",
      Notes: "Notes",
      Paddocks: "Paddocks",
      Page: "Page",
      Password: "Password",
      Purchasers: "Purchasers",
      Purchaser: "Purchaser",
      Search: "Search",
      Show: "Show",
      The: "The",
      Trucker: "Trucker",
      Truckers: "Truckers",
      User: "User",
      Users: "Users",
      Update: "Update",
      Updating: "Updating",
      Voucher_manager: "Voucher manager",

      DataTable: {
        currentPageReportTemplate:
          "Showing {first} to {last} of {totalRecords}",
        New: "New",
        Export: "Export",
        Search: "BusSearchcar",
      },
      Buttons: {
        Cancel: "Cancel",
        Create: "Create",
        Creating: "Creating",
        Update: "Update",
        Updating: "Updating",
        Delete: "Delete",
        Deleting: "Deleting",
      },
      forms: {
        validation: {
          max: "{{entity}} field must be {{max}} or less.",
          min: "{{entity}} field must be {{min}} or more.",
          maxLength: "{{entity}} field lenght must be {{maxLength}} or less",
          required: "{{entity}} is required.",
        },
        messages: {
          operation: {
            success: "Operation performed successfully.",
            error: "An error occurred while trying to perform the operation.",
          },
          titles: {
            Success: "Success",
            Error: "Error",
          },
        },
      },
      errors: {
        general: "An error occurred while trying to perform the operation.",
        login: "An error occurred while trying to perform the login",
      },
      entities: {
        vouchers: {
          plural: "Remitos",
          singular: "Remito",
          created_date: "Fecha de creado",
          harvest_Name: "Zafra",
          history: "history",
          field_Name: "Campo",
          field_Paddocks: "Potreros",
          purchaser_Name: "Comprador",
          destination_Name: "Destino",
          crop_Name: "Cultivo",
          weight: "Peso",
          trucker_Name: "Camionero",
          truckPlate: "Matrícula",
          notes: "Notas",
        },

        trips: {
          plural: "Viajes",
          singular: "Viaje",
          created_date: "Fecha de creado",
          startDate: "Fecha de inicio",
          endDate: "Fecha de fin",
          status: "Estado",
        },
      },
    },
  },
  es: {
    translation: {
      Actions: "Acciones",
      Active: "Activo",
      Add: "Agregar",
      Add_record: "Nuevo registro",
      Administrator: "Administrador",
      Administrators: "Administradores",
      An_error_occurred_while_trying_to_perform_the_operation:
        "Ocurrió un error al intentar realizar la operación",
      and: "y",
      Back: "Volver",
      characters: "caracteres",
      Configurations: "Configuraciones",
      Created_date: "Fecha de inicio",
      Crop: "Cultivo",
      Crops: "Cultivos",
      Delete: "Borrar",
      Destinations: "Destinos",
      Destination: "Destino",
      Edit: "Editar",
      End_date: "Fecha de fin",
      Fields: "Campos",
      Field: "Campo",
      Go_to_page: "Ir a la página",
      Harvests: "Zafras",
      Harvest: "Zafra",
      Home: "Inicio",
      Hopper: "Tolvero",
      Hoppers: "Tolveros",
      is_required: "es requerido",
      Latitude: "Latitud",
      Longitude: "Longitud",
      Login: "Iniciar sesión",
      Login_failed: "Error de inicio de sesion",
      Logistics_providers: "Proveedores de logística",
      Logistics_provider: "Proveedor de logística",
      Logout: "Salir",
      must_not_exceed: "no debe exceder",
      Name: "Nombre",
      Notes: "Notas",
      profile: "Perfil",
      Purchasers: "Compradores",
      Purchaser: "Comprador",
      Paddocks: "Potreros",
      Page: "Página",
      Password: "Contraseña",
      Search: "Buscar",
      Show: "Mostrar",
      The: "El",
      Trucker: "Camionero",
      Truckers: "Camioneros",
      Users: "Usuarios",
      User: "Usuario",
      Update: "Actualizar",
      Updating: "Actualizando",
      Voucher_manager: "Administrador de remitos",
      sitemap: {
        configuration: "Configuración",
        vouchers: "Remitos",
        fields: "Campos",
        destinations: "Destinos",
        logisticsProviders: "Proveedores de logísitca",
        truckers: "Camioneros",
        hoppers: "Tolveros",
        administrators: "Administradores",
        profile: "Perfil",
        crops: "Cultivos",
        purchasers: "Compradores"
      },
      DataTable: {
        currentPageReportTemplate:
          "Mostrando de {first} hasta {last} de {totalRecords}",
        New: "Nuevo",
        Export: "Exportar",
        Search: "Buscar",
      },
      buttons: {
        cancel: "Cancelar",
        canceling: "Cancelando",
        create: "Crear",
        creating: "Creando",
        update: "Actualizar",
        updating: "Actualizando",
        delete: "Eliminar",
        deleting: "Eliminando",
        close: "Cerrar",
        closing: "Cerrando",
        yes: "Si",
        no: "No",
      },
      forms: {
        validation: {
          max: "{{entity}} debe ser menor o igual a {{max}}.",
          min: "{{entity}} debe ser mayor o igual a {{min}}.",
          maxLength: "{{entity}} no debe superar los {{maxLength}} caracteres.",
          required: "{{entity}} es requerido.",
          noValidEmail: "Email no válido.",
          validateMaxMin: "{{entity}} debe de estar entre {{min}} y {{max}}."
        },
        messages: {
          operation: {
            success: "Operación realizada con exito.",
            error:
              "Un error ocurrió mientras se intentaba realizar la operación.",
          },
          titles: {
            success: "Exito",
            error: "Error",
            confirmation: "Confirmación",
          },
        },
        options: {
          toContinue: "¿Desea continuar?",
        },
      },
      errors: {
        general:
          "Un error ocurrió mientras se intentaba realizar la operación.",
        login: "Usuario y/o contraseña no válidos",
        vouchers: {
          no_open_harvest: "No hay una Zafra abierta."
        }
      },
      entities: {
        administrators: {
          plural: "Administradores",
          singular: "Administrador",
          name: "Nombre",
          phone: "Teléfono",
          notes: "Notas",
          active: "Activo",
          nick: "Usuario",
          email: "Email",
          password: "Contraseña",
          hidePassword: "Ocultar contraseña",
          showPassword: "Mostrar contraseña",
        },
        clients: {
          plural: "Clientes",
          singular: "Cliente",
          name: "Nombre",
          address: "Dirección",
          phone: "Teléfono",
          rut: "Rut",
          logo: "Logo",
          city: "Ciudad",
          email: "Email",
          notes: "Notas",
          active: "Activo",
        },
        crops: {
          plural: "Cultivos",
          singular: "Cultivo",
          name: "Nombre",
          notes: "Notas",
          active: "Activo",
        },
        destinations: {
          plural: "Destinos",
          singular: "Destino",
          name: "Nombre",
          email: "Email",
          latitude: "Latitud",
          longitude: "Longitud",
          notes: "Notas",
          active: "Activo",
        },
        fields: {
          plural: "Campos",
          singular: "Campo",
          name: "Nombre",
          latitude: "Latitud",
          longitude: "Longitud",
          paddocks: "Potreros",
          notes: "Notas",
          active: "Activo",
        },
        harvests: {
          plural: "Zafras",
          singular: "Zafra",
          name: "Nombre",
          created_date: "Creada",
          endDate: "Finalizada",
          notes: "Notas",
          confirmCreateAction:
            "Atención, la creación de una nueva zafra implica el cierre de la zafra actual.",
        },
        hoppers: {
          plural: "Tolveros",
          singular: "Tolvero",
          name: "Nombre",
          phone: "Teléfono",
          notes: "Notas",
          active: "Activo",
          nick: "Usuario",
          email: "Email",
          password: "Contraseña",
          hidePassword: "Ocultar contraseña",
          showPassword: "Mostrar contraseña",
        },
        logisticsProviders: {
          plural: "Proveedores de logística",
          singular: "Proveedor de logística",
          name: "Nombre",
          email: "Email",
          notes: "Notas",
          active: "Activo",
        },
        purchasers: {
          plural: "Compradores",
          singular: "Comprador",
          name: "Nombre",
          address: "Dirección",
          phone: "Teléfono",
          notes: "Notas",
          emails: "Emails",
          active: "Activo",
        },
        Paddocks: "Potreros",
        truckers: {
          plural: "Camioneros",
          singular: "Camionero",
          name: "Nombre",
          phone: "Teléfono",
          notes: "Notas",
          active: "Activo",
          nick: "Usuario",
          email: "Email",
          password: "Contraseña",
          hidePassword: "Ocultar contraseña",
          showPassword: "Mostrar contraseña",
        },

        users: {
          plural: "Usuarios",
          singular: "Usuario",

        },

        vouchers: {
          actives: "Activos",
          finished: "Finalizados",
          cancel: "Cancelados",
          plural: "Remitos",
          pluralActives: "Remitos Activos",
          singular: "Remito",
          created_date: "Fecha de creado",
          cancelledVouchers: "Remitos cancelados",
          createdDate: "Fecha de creado",
          harvest_Name: "Zafra",
          history: "Historial",
          field_Name: "Campo",
          field_Paddocks: "Potreros",
          final_weight: "Peso final",
          purchaser_Name: "Comprador",
          destination_Name: "Destino",
          crop_Name: "Cultivo",
          weight: "Peso",
          trucker_Name: "Camionero",
          truckPlate: "Matrícula",
          notes: "Notas",
          number: "#",
          km: "Km"
        },

        trips: {
          plural: "Viajes",
          singular: "Viaje",
          created_date: "Fecha de creado",
          startDate: "Fecha de inicio",
          endDate: "Fecha de fin",
          status: "Estado",
        },
      },
    },
  },
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    lng: "es",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    objectNotation: true,
    resources,
  });

export default i18n;

// i18n.use(initReactI18next).init({
//   resources,
//   lng: 'es', // Establece el idioma predeterminado aquí
//   keySeparator: false,
//   interpolation: {
//     escapeValue: false,
//   },
// });

// export default i18n;
