import React, { useState, useEffect } from "react";
import { Checkbox } from "primereact/checkbox";

const ActiveInactive = (props) => {
  const [checked, setChecked] = useState(props.value);

  const onChange = async (e) => {
    try {
      await props.onChange(props.itemId, e.checked);
      setChecked(e.checked);
    } catch (error) {
      // Handle the error if needed
      console.error("Error in onChange:", error);
      // Revert to the previous state on error
      setChecked(!e.checked);
    }
  };

  useEffect(() => {
    // Update the state when props.value changes
    setChecked(props.value);
  }, [props.value]);

  return (
    <div className="card flex justify-content-center">
      <Checkbox
        onChange={onChange}
        checked={checked}
        disabled={props.disabled}
      ></Checkbox>
    </div>
  );
};

export default ActiveInactive;
