// hooks/useApi.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import env from "@beam-australia/react-env";

const axiosInstance = axios.create({
  baseURL: `${env("API_URL")}`,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 150000,
  timeoutErrorMessage: 'Tiempo de espera excedido',
});

const useAxiosInterceptor = () => {
  const navigate = useNavigate();

  const navigateToLogin = () => {
    navigate("/");
  }

  const refreshToken = async () => {
    const token = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    const newApi = axios.create({
      baseURL: `${env("API_URL")}`,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const body = JSON.stringify({
      token,
      refreshToken,
      origin: 'backoffice',
    });

    const response = await newApi.post('auth/refreshtoken', body);

    localStorage.setItem('accessToken', response.data.accessToken.token);
    localStorage.setItem('refreshToken', response.data.refreshToken);

    return response.data.accessToken;
  };

  axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  useEffect(() => {
    const interceptor = axiosInstance.interceptors.response.use(
      response => response,
      async error => {
        if (error?.response?.status === 401) {
          const originalRequest = error.config;

          try {
            const responseRefreshToken = await refreshToken();

            if (responseRefreshToken) {

              originalRequest.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')} `;

              return axiosInstance(originalRequest);
            }
          } catch (err) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            navigateToLogin();
          }
        }
        return Promise.reject(error);
      },
    );

    return () => {
      axiosInstance.interceptors.response.eject(interceptor);
    };
  }, [navigate]);
};

const useApi = () => {
  useAxiosInterceptor();
  return axiosInstance;
};

export default useApi;
