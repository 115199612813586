import React from "react";
import useSkeleton from "../hooks/useSkeleton";

const Header = (props) => {
  const s = useSkeleton("layout.content.header");

  return (
    <header className={s.get()}>
      <div className="flex text-6xl pl-3">{props.icon}</div>
      <div className={s.get("info")}>
        <div className={s.get("info.route")}>{props.route}</div>
        <div className={s.get("info.title")}>{props.title}</div>
      </div>

      <div className={s.get("actions")}>{props.actions}</div>
    </header>
  );
};

export default Header;
