import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import env from "@beam-australia/react-env";
import useApi from "../../../hooks/useApi";
import "react-toastify/dist/ReactToastify.css";

import Map from "../../../components/map/map";
import DateTimeHelper from "../../../utils/dateTimeHelper";

import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";

const VoucherDetail = ({ settings }) => {
  const { t } = useTranslation();
  const api = useApi();

  const [isLoading, setIsLoading] = useState(false);
  const [voucher, setVoucher] = useState({});
  const [markersArray, setMarkersArray] = useState([]);

  const fetchVoucher = async () => {
    try {
      const responseGetVoucher = await settings.CRUDService.getEntity(
        settings.entityId
      );

      console.log(responseGetVoucher);

      const responseGetTrip = await api.get(
        `/trips/${responseGetVoucher.trip.id}`
      );

      console.log(responseGetVoucher.trip.id);
      console.log(responseGetTrip);
      const latitudeLongitudeArray =
        responseGetTrip.data.tripsLocationsCollection;

      // const latitudeLongitudeArray =
      //   responseGetTrip.data.tripsLocationsCollection.filter((item) => {
      //     const location = JSON.parse(item.location);
      //     //  if (location.activity.type !== "unknown") {
      //     return item;
      //     //   }
      //   });

      setMarkersArray(latitudeLongitudeArray);

      setVoucher(responseGetVoucher);
    } catch (error) {
      console.error(t("forms.messages.operation.error"), error);
    }
  };

  let getlocations;

  useEffect(() => {
    if (
      voucher?.trip?.status != "Finished" &&
      voucher?.trip?.status != "Pending"
    ) {
      fetchVoucher();
      getlocations = setInterval(() => {
        fetchVoucher();
      }, parseInt(env("PULL_VOUCHER"), 10) || 60000);
    } else {
      clearInterval(getlocations);
    }

    return () => {
      clearInterval(getlocations);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateFinalWeight = async () => {
    setIsLoading(true);

    try {
      await api.put(`/vouchers/updatefinalweight/${voucher.id}`, {
        id: voucher.id,
        finalWeight: voucher.finalWeight,
        clientId: voucher.clientId,
      });

      settings.toast.current.show({
        severity: "success",
        summary: t("forms.messages.titles.success"),
        detail: t("forms.messages.operation.success"),
        life: 3000,
      });
    } catch (error) {
      settings.toast.current.show({
        severity: "error",
        summary: t("forms.messages.titles.error"),
        detail: t("forms.messages.operation.error"),
        life: 3000,
      });
    }

    setIsLoading(false);
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        {/* Este contenedor centrará el contenido horizontalmente */}
        <div
          className="content-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Este contenedor ayudará a que las columnas no cambien su distribución */}
          <div
            className="main-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              maxWidth: "1200px",
            }}
          >
            {/* Aquí se especifica el ancho máximo del contenedor principal */}
            {/* Columna 1 */}
            <div style={{ flex: 1 }}>
              <div
                className="container"
                style={{
                  display: "flex",
                  marginBottom: "10px",
                }}
              >
                <div style={({ marginRight: "20px" }, { minWidth: "150px" })}>
                  {t("entities.vouchers.harvest_Name")}
                </div>
                <div className="value" style={{ minWidth: "150px" }}>
                  {voucher.harvest_Name}
                </div>
              </div>
              <div
                className="container"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                <div style={({ marginRight: "20px" }, { minWidth: "150px" })}>
                  {t("entities.vouchers.created_date")}
                </div>
                <div className="value">
                  {!!voucher.createdDate &&
                    DateTimeHelper.dateTemplate(voucher.createdDate)}
                </div>
              </div>
              <div
                className="container"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                <div style={({ marginRight: "20px" }, { minWidth: "150px" })}>
                  {t("entities.vouchers.purchaser_Name")}
                </div>
                <div className="value">{voucher.purchaser_Name}</div>
              </div>
              <div
                className="container"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                <div style={({ marginRight: "20px" }, { minWidth: "150px" })}>
                  {t("entities.vouchers.destination_Name")}
                </div>
                <div className="value">{voucher.destination_Name}</div>
              </div>
              <div
                className="container"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                <div style={({ marginRight: "20px" }, { minWidth: "150px" })}>
                  {t("entities.vouchers.trucker_Name")}
                </div>
                <div className="value">{voucher.trucker_Name}</div>
              </div>
            </div>
            {/* Columna 2 */}
            <div style={{ flex: 1 }}>
              <div
                className="container"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                <div style={({ marginRight: "20px" }, { minWidth: "150px" })}>
                  {t("entities.vouchers.crop_Name")}
                </div>
                <div className="value">{voucher.crop_Name}</div>
              </div>
              <div
                className="container"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                <div style={({ marginRight: "20px" }, { minWidth: "150px" })}>
                  {t("entities.vouchers.field_Name")}
                </div>
                <div className="value">{voucher.field_Name}</div>
              </div>
              <div
                className="container"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                <div style={({ marginRight: "20px" }, { minWidth: "150px" })}>
                  {t("entities.vouchers.field_Paddocks")}
                </div>
                <div className="value">{voucher.field_Paddocks}</div>
              </div>
              <div
                className="container"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                <div style={({ marginRight: "20px" }, { minWidth: "150px" })}>
                  {t("entities.vouchers.weight")}
                </div>
                <div className="value">{voucher.weight}</div>
              </div>
              {voucher?.trip?.status == "Finished" && (
                <div
                  className="container"
                  style={{ display: "flex", marginBottom: "10px" }}
                >
                  <div style={({ marginRight: "20px" }, { minWidth: "150px" })}>
                    {t("entities.vouchers.final_weight")}
                  </div>
                  <div className="value">
                    <InputNumber
                      value={voucher.finalWeight}
                      onValueChange={(e) =>
                        setVoucher({ ...voucher, finalWeight: e.value })
                      }
                      suffix=" Kg"
                      style={{ maxWidth: "100px", marginRight: "10px" }}
                    />
                  </div>
                  <div className="value">
                    <Button
                      icon={isLoading ? "WAIT" : "pi pi-save"}
                      type="submit"
                      disabled={isLoading}
                      onClick={updateFinalWeight}
                      style={{ float: "left" }}
                    />
                  </div>
                </div>
              )}
              <div
                className="container"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                <div style={({ marginRight: "20px" }, { minWidth: "150px" })}>
                  {t("entities.vouchers.truckPlate")}
                </div>
                <div className="value">{voucher.truckPlate}</div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="content-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Este contenedor VIAJE */}
          <div
            className="main-container"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              maxWidth: "1200px",
            }}
          >
            <h5 style={({ marginRight: "20px" }, { minWidth: "150px" })}>
              {t("entities.trips.singular")}
            </h5>
            {/* Aquí se especifica el ancho máximo del contenedor principal */}
            {/* Columna 1 */}
            <div style={{ flex: 1 }}>
              <div
                className="container"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                <div style={({ marginRight: "20px" }, { minWidth: "150px" })}>
                  {t("entities.trips.created_date")}
                </div>
                <div className="value" style={{ minWidth: "150px" }}>
                  {!!voucher?.trip?.createdDate &&
                    DateTimeHelper.dateTemplate(voucher.trip.createdDate)}
                </div>
              </div>
              <div
                className="container"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                <div style={({ marginRight: "20px" }, { minWidth: "150px" })}>
                  {t("entities.trips.startDate")}
                </div>
                <div className="value">
                  {!!voucher?.trip?.startDate &&
                    DateTimeHelper.dateTemplate(voucher.trip.startDate)}
                </div>
              </div>

              <div
                className="container"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                <div style={({ marginRight: "20px" }, { minWidth: "150px" })}>
                  {t("entities.trips.endDate")}
                </div>
                <div className="value">
                  {!!voucher?.trip?.endDate &&
                    DateTimeHelper.dateTemplate(voucher.trip.endDate)}
                </div>
              </div>

              <div
                className="container"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                <div style={({ marginRight: "20px" }, { minWidth: "150px" })}>
                  {t("entities.trips.status")}
                </div>
                <div className="value">{voucher?.trip?.status}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {voucher.trip?.status === "Pending" ? (
        <div
          className="content-container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <b>El viaje aún no a comenzado.</b>
        </div>
      ) : (
        <Map markers={markersArray} />
      )}
    </React.Fragment>
  );
};

export default VoucherDetail;
