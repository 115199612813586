import React, { useMemo } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import env from "@beam-australia/react-env";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const LocationPicker = ({ hideMap, getLocation }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: env("GOOGLE_MAPS_API_KEY"),
    libraries: ["geometry", "drawing"],
  });

  const options = useMemo(
    () => ({
      mapId: env("GOOGLE_MAPS_ID"),
      disableDefaultUI: true,
      draggableCursor: "copy",
    }),
    []
  );

  return (
    isLoaded && (
      <GoogleMap
        options={options}
        mapContainerStyle={containerStyle}
        center={{
          lat: -32.5157991,
          lng: -55.8348566,
        }}
        zoom={6.5} // Puedes ajustar este valor según lo que necesites
        onClick={(e) => {
          getLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
          hideMap();
        }}
      ></GoogleMap>
    )
  );
};

export default LocationPicker;
