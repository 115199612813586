import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import env from "@beam-australia/react-env";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

import useApi from "../../../hooks/useApi";

import "react-toastify/dist/ReactToastify.css";
import { ConfirmDialog } from "primereact/confirmdialog";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import "../crud.css";

import moment from "moment";

const HarvestsForm = ({ settings }) => {
  const { t } = useTranslation();
  const api = useApi();

  const harvestClosedMode = settings.formMode === "CLOSE";

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const defaultHarvestsData = {
    name: "",
    endDate: undefined,
    notes: "",
    clientId: 0,
  };

  const [initComplete, setInitComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [formData, setFormData] = useState(null);
  const [entityData, setEntityData] = useState(defaultHarvestsData);
  const currentEntityValues = useRef(defaultHarvestsData);

  const formModeConfig = {};

  if (harvestClosedMode) {
    formModeConfig.SubmitButtonText = t("buttons.close");
    formModeConfig.SubmitButtonTextWait = `${t("buttons.closing")}...`;
    formModeConfig.serverAction = async (data) => {
      await api.put(`/harvests/close`, data);
      localStorage.removeItem("currentHarvestId");
    };
  } else {
    formModeConfig.SubmitButtonText = t("buttons.create");
    formModeConfig.SubmitButtonTextWait = `${t("buttons.creating")}...`;
    formModeConfig.serverAction = async (data) => {
      await settings.CRUDService.createEntity(data);
      localStorage.removeItem("currentHarvestId");
    };
  }

  useEffect(() => {
    if (harvestClosedMode) {
      const fetchEntity = async () => {
        try {
          const data = await settings.CRUDService.getEntity(settings.entityId);
          const mergedData = {
            ...defaultHarvestsData,
            ...data,
          };
          setEntityData(mergedData);
          for (const key in mergedData) {
            setValue(key, mergedData[key]);
          }
        } catch (error) {
          console.error(t("forms.messages.operation.error"), error);
        }
      };

      fetchEntity();
    }
    setInitComplete(true);
  }, [settings.entityId, setValue, settings.formMode]);

  const onSubmit = (data) => {
    setFormData(data);
    if (harvestClosedMode) {
      acceptCreateAction();
    } else {
      setShowConfirmDialog(true);
    }
  };

  const acceptCreateAction = async () => {
    setIsLoading(true);
    try {
      let result = await formModeConfig.serverAction(formData);
      settings.hideDialog();

      settings.toast.current.show({
        severity: "success",
        summary: t("forms.messages.titles.success"),
        detail: t("forms.messages.operation.success"),
        life: 3000,
      });
    } catch (error) {
      settings.toast.current.show({
        severity: "error",
        summary: t("forms.messages.titles.error"),
        detail: t("forms.messages.operation.error"),
        life: 3000,
      });

      setIsLoading(false);
    }
    setShowConfirmDialog(false);
  };

  const rejectCreateAction = () => {
    setShowConfirmDialog(false);
  };

  const handleButtonAction = () => {
    if (!initComplete) {
      return null;
    }

    if (!harvestClosedMode || !entityData.endDate) {
      return (
        <Button
          label={
            isLoading
              ? formModeConfig.SubmitButtonTextWait
              : formModeConfig.SubmitButtonText
          }
          icon="pi pi-check"
          className=" btn btn-primary btn-primary-crud"
          type="submit"
          disabled={isLoading}
        />
      );
    }
  };
  const getFormErrorMessage = (error) => {
    return error && <small className="p-error">{error.message}</small>;
  };

  const confirmationMessage = () => {
    return (
      <div style={{ width: "400px" }}>
        <p>{t("entities.harvests.confirmCreateAction")}</p>
        <p className="option">{t("forms.options.toContinue")}</p>
      </div>
    );
  };

  const dateTemplate = (date) => {
    return date == "0001-01-01T00:00:00"
      ? ""
      : moment(date).format("YYYY-MM-DD");
  };

  return (
    <React.Fragment>
      <div className="flex justify-content-center">
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <div>
            <Controller
              name="name"
              control={control}
              defaultValue={defaultHarvestsData.name}
              rules={{
                required: t("forms.validation.required", {
                  entity: t("entities.harvests.name"),
                }),
                maxLength: {
                  value: 500,
                  message: t("forms.validation.maxLength", {
                    entity: t("entities.harvests.name"),
                    maxLength: 500,
                  }),
                },
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <div className="flex justify-content-between">
                    <label htmlFor="name">{t("entities.harvests.name")}</label>
                    <div>{getFormErrorMessage(errors.name)}</div>
                  </div>
                  <div className="form-crud">
                    <InputText
                      id="name"
                      {...field}
                      value={field.value || ""}
                      maxLength={100}
                      className={[
                        "form-control",
                        classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                      ]}
                      disabled={harvestClosedMode}
                    />
                  </div>
                </React.Fragment>
              )}
            />
          </div>
          {harvestClosedMode && (
            <div>
              <Controller
                name="createdDate"
                control={control}
                defaultValue={defaultHarvestsData.createdDate}
                render={({ field, fieldState }) => (
                  <React.Fragment>
                    <div className="flex justify-content-between">
                      <label htmlFor="createdDate">
                        {t("entities.harvests.created_date")}
                      </label>
                    </div>
                    <div className="form-crud">
                      <InputText
                        id="createdDate"
                        {...field}
                        value={dateTemplate(field.value) || ""}
                        maxLength={100}
                        className="form-control"
                        disabled
                      />
                    </div>
                  </React.Fragment>
                )}
              />
            </div>
          )}
          {harvestClosedMode && currentEntityValues.current.endDate && (
            <div>
              <Controller
                name="endDate"
                control={control}
                defaultValue={defaultHarvestsData.endDate}
                render={({ field }) => (
                  <React.Fragment>
                    <div className="flex justify-content-between">
                      <label htmlFor="endDate">
                        {t("entities.harvests.endDate")}
                      </label>
                    </div>
                    <div className="form-crud">
                      <InputText
                        id="endDate"
                        {...field}
                        value={dateTemplate(field.value) || ""}
                        maxLength={100}
                        className="form-control"
                        disabled
                      />
                    </div>
                  </React.Fragment>
                )}
              />
            </div>
          )}

          <div>
            <Controller
              name="notes"
              control={control}
              defaultValue={defaultHarvestsData.notes}
              rules={{
                maxLength: {
                  value: 500,
                  message: t("forms.validation.maxLength", {
                    entity: t("entities.harvests.notes"),
                    maxLength: 500,
                  }),
                },
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <div className="flex justify-content-between">
                    <label htmlFor="notes">
                      {t("entities.harvests.notes")}
                    </label>
                    <div>{getFormErrorMessage(errors.notes)}</div>
                  </div>
                  <div className="form-crud">
                    <InputTextarea
                      id="notes"
                      {...field}
                      value={field.value ? field.value : ""}
                      rows={3}
                      maxLength={500}
                      className={[
                        "form-control",
                        classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                      ]}
                      disabled={harvestClosedMode}
                    />
                  </div>
                </React.Fragment>
              )}
            />
          </div>
          <div className="flex justify-content-between">
            <Button
              label={t("buttons.cancel")}
              icon="pi pi-times"
              className="p-button-text btn-secondary-crud"
              onClick={() => settings.hideDialog("CANCEL")}
              type="button"
              disabled={isLoading}
            />
            {handleButtonAction()}
          </div>
        </form>

        <ConfirmDialog
          visible={showConfirmDialog}
          onHide={() => setShowConfirmDialog(false)}
          message={confirmationMessage}
          header={t("forms.messages.titles.confirmation")}
          accept={acceptCreateAction}
          reject={rejectCreateAction}
          acceptLabel={t("buttons.yes")}
          rejectLabel={t("buttons.no")}
        />
      </div>
    </React.Fragment>
  );
};

export default HarvestsForm;
