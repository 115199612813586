import React, { useState, useEffect, useRef, useCallback } from "react";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";

import { useDebounce } from "../../hooks/useDebounce";

import useCRUDService from "../../hooks/useCRUDService";
import useSkeleton from "../../hooks/useSkeleton";

export default function Searcher({ settings }) {
  const s = useSkeleton("commons");
  const CRUDService = useCRUDService(settings.entityName);

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [globalFilter, setGlobalFilter] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [entities, setEntities] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  const dt = useRef(null);
  const searchInput = useRef(null);

  const { t } = useTranslation();
  const debouncedSearchTerm = useDebounce(globalFilter, 2000);

  const op = useRef(null);
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current && settings.selectedEntity) {
      setFirst(0);
      setRows(10);
      setSortField(null);
      setSortOrder(null);
      setGlobalFilter("");
      isMounted.current = false;
    }
  }, [settings.selectedEntity]);

  const fetchData = useCallback(async () => {
    if (!settings.disabled) {
      setIsLoading(true);

      const response = settings.fetchData
        ? await settings.fetchData(
            settings.columns,
            first,
            rows,
            sortField ?? settings.defaultSortField,
            sortOrder >= 0 ? "ASC" : "DESC",
            debouncedSearchTerm
          )
        : await CRUDService.getEntities(
            settings.columns,
            first,
            rows,
            sortField ?? settings.defaultSortField,
            sortOrder >= 0 ? "ASC" : "DESC",
            debouncedSearchTerm,
            null,
            "actives"
          );
      setIsLoading(false);
      setTotalRecords(response.data.totalRows);
      setEntities(response.data.rows);
    }
  }, [first, rows, sortField, sortOrder, debouncedSearchTerm, CRUDService]);

  useEffect(() => {
    if (isMounted.current) {
      const fetchData = async () => {
        if (!settings.disabled) {
          setIsLoading(true);

          const response = settings.fetchData
            ? await settings.fetchData(
                settings.columns,
                first,
                rows,
                sortField ?? settings.defaultSortField,
                sortOrder >= 0 ? "ASC" : "DESC",
                debouncedSearchTerm
              )
            : await CRUDService.getEntities(
                settings.columns,
                first,
                rows,
                sortField ?? settings.defaultSortField,
                sortOrder >= 0 ? "ASC" : "DESC",
                debouncedSearchTerm,
                null,
                "actives"
              );
          setIsLoading(false);
          setTotalRecords(response.data.totalRows);
          setEntities(response.data.rows);
        }
      };

      fetchData();
    }
  }, [
    settings.columns,
    first,
    rows,
    sortField,
    sortOrder,
    debouncedSearchTerm,
    isMounted.current,
  ]);
  //
  const header = (
    <div className="flex justify-content-between">
      <h5 className="mx-0 my-1 searcherTitle">
        <i className="fa-solid fa-globe" style={{ paddingRight: "10px" }}></i>
        {t(`entities.${settings.entityName}.plural`)}
      </h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          ref={searchInput}
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder={`${t("DataTable.Search")}...`}
        />
      </span>
    </div>
  );

  const openOverlayPanel = (e) => {
    setIsVisible(true);
    isMounted.current = true;
  };

  const onSelectEntity = (e) => {
    setFirst(0);
    setRows(10);
    setSortField(null);
    setSortOrder(null);
    setGlobalFilter("");
    // Restablece el valor del campo de entrada
    if (searchInput.current) {
      searchInput.current.value = "";
    }

    settings.setSelectedEntity(e.value); //Todos los valores de la entidad
    setIsVisible(false);
    isMounted.current = false;
  };

  return (
    <React.Fragment>
      <Button
        type="button"
        icon="pi pi-search"
        style={{
          width: "42px",
          height: "32px",
          borderTopRightRadius: "3px",
          borderBottomRightRadius: "3px",
        }}
        className="p-button-search"
        onClick={openOverlayPanel}
        disabled={settings.disabled}
      />
      <div>
        <Sidebar
          style={{
            maxHeight: `${290 + 57 * 5}px`,
            height: `${290 + 57 * Math.min(Math.max(1, totalRecords), 5)}px`,
          }}
          visible={isVisible}
          position="bottom"
          onHide={() => setIsVisible(false)}
        >
          <div className={s.get("dataTableContainer")}>
            <DataTable
              onPage={(e) => {
                setFirst(e.first);
                setRows(e.rows);
              }}
              onSort={(e) => {
                setSortField(e.sortField);
                setSortOrder(e.sortOrder);
              }}
              sortField={sortField}
              sortOrder={sortOrder}
              ref={dt}
              value={entities}
              lazy
              paginator
              first={first}
              rows={rows}
              totalRecords={totalRecords}
              resizableColumns
              columnResizeMode="fit"
              showGridlines
              dataKey="id"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate={`${t(
                "DataTable.currentPageReportTemplate"
              )} ${t(`entities.${settings.entityName}.plural`)}`}
              globalFilter={globalFilter}
              header={header}
              selectionMode="single"
              selection={settings.selectedEntity}
              onSelectionChange={onSelectEntity}
            >
              {settings.tableColumns}
            </DataTable>
          </div>
        </Sidebar>
      </div>
    </React.Fragment>
  );
}
