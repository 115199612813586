import React, { useState, useRef } from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import env from "@beam-australia/react-env";

import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";
import axios from "axios";
import "./login.css";

const Login = () => {
  const { t } = useTranslation();

  const toast = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nick: "",
      password: "",
    },
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${env("API_URL")}/auth/backoffice`,
        data
      );

      localStorage.setItem("accessToken", response.data.accessToken.token);
      localStorage.setItem("refreshToken", response.data.refreshToken);

      const decodedToken = jwtDecode(response.data.accessToken.token);
      localStorage.setItem("userName", decodedToken.name);
      localStorage.setItem("clientId", decodedToken.clientId);
      navigate("/vouchersactives");
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: t("errors.login"),
        life: 3000,
      });

      setIsLoading(false);
    }
  };

  const getFormErrorMessage = (error) => {
    return error && <small className="p-error">{error.message}</small>;
  };

  return (
    <React.Fragment>
      <Toast ref={toast} />
      <div className="login">
        <Card>
          <h1>{t("Voucher_manager")}</h1>
          <div className="container">
            <div className="flex justify-content-center">
              <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                <div className="field">
                  <Controller
                    name="nick"
                    control={control}
                    rules={{
                      required: t("forms.validation.required", {
                        entity: t("entities.administrators.nick"),
                      }),
                      maxLength: {
                        value: 30,
                        message: t("forms.validation.maxLength", {
                          entity: t("entities.administrators.nick"),
                          maxLength: 30,
                        }),
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <React.Fragment>
                        <div className="flex justify-content-between">
                          <label htmlFor="nick">
                            {t("entities.administrators.nick")}
                          </label>
                          <div>{getFormErrorMessage(errors.nick)}</div>
                        </div>
                        <div className="form-crud">
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                              <i className="pi pi-user"></i>
                            </span>
                            <span className="p-float-label">
                              <InputText
                                id="nick"
                                {...field}
                                value={field.value || ""}
                                maxLength={30}
                                className={[
                                  "form-control",
                                  classNames({
                                    "p-invalid": fieldState.invalid,
                                  }),
                                ]}
                              />
                            </span>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  />
                </div>

                <div className="field">
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: t("forms.validation.required", {
                        entity: t("entities.administrators.password"),
                      }),
                      maxLength: {
                        value: 30,
                        message: t("forms.validation.maxLength", {
                          entity: t("entities.administrators.password"),
                          maxLength: 30,
                        }),
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <React.Fragment>
                        <div className="flex justify-content-between">
                          <label htmlFor="password">
                            {t("entities.administrators.password")}
                          </label>
                          <div>{getFormErrorMessage(errors.password)}</div>
                        </div>
                        <div className="form-crud">
                          <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                              <i className="pi pi-key"></i>
                            </span>
                            <span className="p-float-label">
                              <Password
                                id="password"
                                {...field}
                                inputRef={field.ref}
                                className={classNames({
                                  "p-invalid": fieldState.error,
                                })}
                                feedback={false}
                              />
                            </span>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  />
                </div>

                <Button
                  label={t("Login")}
                  icon="pi-sign-in pi "
                  className="p-button-raised col-12 md:col-4 p-button-primary"
                  loading={isLoading}
                />
              </form>
            </div>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Login;
