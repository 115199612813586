import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Password } from "primereact/password";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

import "react-toastify/dist/ReactToastify.css";
import "../../crud.css";

const HoppersForm = ({ settings }) => {
  const { t } = useTranslation();

  const isEditMode = settings.formMode === "UPDATE";
  const isDeleteMode = settings.formMode === "DELETE";

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      phone: "",
      nick: "",
      email: "",
      //password: "",
      notes: "",
      active: true,
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  let passwordMode = "HIDDEN"; // "UPDATE", "VISIBLE"

  const formModeConfig = {};

  if (isEditMode) {
    passwordMode = "UPDATE";
    formModeConfig.SubmitButtonText = t("buttons.update");
    formModeConfig.SubmitButtonTextWait = `${t("buttons.updating")}...`;
    formModeConfig.serverAction = async (data) => {
      await settings.CRUDService.updateEntity(data);
    };
  } else if (isDeleteMode) {
    formModeConfig.SubmitButtonText = t("buttons.delete");
    formModeConfig.SubmitButtonTextWait = `${t("buttons.deleting")}...`;
    formModeConfig.serverAction = async () => {
      await settings.CRUDService.deleteEntity(settings.entityId);
    };
  } else {
    passwordMode = "VISIBLE";
    formModeConfig.SubmitButtonText = t("buttons.create");
    formModeConfig.SubmitButtonTextWait = `${t("buttons.creating")}...`;
    formModeConfig.serverAction = async (data) => {
      const newAdministrator = {
        name: data.name,
        phone: data.phone,
        notes: data.notes,
        active: data.active,
        nick: data.nick,
        email: data.email,
        password: data.password,
      };
      await settings.CRUDService.createEntity(newAdministrator);
    };
  }

  useEffect(() => {
    if (isEditMode || isDeleteMode) {
      const fetchCrop = async () => {
        try {
          const data = await settings.CRUDService.getEntity(settings.entityId);

          for (const key in data) {
            setValue(key, data[key]);
          }
        } catch (error) {
          console.error(t("forms.messages.operation.error"), error);
        }
      };

      fetchCrop();
    }
  }, [settings.entityId, setValue, settings.formMode]);

  const handlePassword = (e) => {
    e.preventDefault();
    passwordMode = "VISIBLE";
  };

  const [isVisible, setIsVisible] = useState(passwordMode == "VISIBLE");

  const handleShow = (e) => {
    e.preventDefault();
    setIsVisible(!isVisible);
  };

  const handleHide = () => {
    setIsVisible(false);
  };

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      await formModeConfig.serverAction(data);

      settings.hideDialog();

      settings.toast.current.show({
        severity: "success",
        summary: t("forms.messages.titles.success"),
        detail: t("forms.messages.operation.success"),
        life: 3000,
      });
    } catch (error) {
      let detail = t("forms.messages.operation.error");
      if (error.response.status == 409) {
        if (error.response.data.rule == "IX_Users_Nick") {
          detail = `Ya existe un usuario con el nombre ${error.response.data.value}`;
        }
      }

      settings.toast.current.show({
        severity: "error",
        summary: t("forms.messages.titles.error"),
        detail: detail,
        life: 3000,
      });

      setIsLoading(false);
    }
  };

  const getFormErrorMessage = (error) => {
    return error && <small className="p-error">{error.message}</small>;
  };

  return (
    <React.Fragment>
      <div className="flex justify-content-center">
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <div className="field">
            <Controller
              name="name"
              control={control}
              rules={
                settings.formMode == "DELETE"
                  ? ""
                  : {
                      required: t("forms.validation.required", {
                        entity: t("entities.hoppers.name"),
                      }),
                      maxLength: {
                        value: 500,
                        message: t("forms.validation.maxLength", {
                          entity: t("entities.hoppers.name"),
                          maxLength: 500,
                        }),
                      },
                    }
              }
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <div className="flex justify-content-between">
                    <label htmlFor="name">{t("entities.hoppers.name")}</label>
                    <div>{getFormErrorMessage(errors.name)}</div>
                  </div>
                  <div className="form-crud">
                    <InputText
                      id={field.name}
                      {...field}
                      value={field.value || ""}
                      maxLength={100}
                      className={[
                        "form-control",
                        classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                      ]}
                      disabled={settings.formMode === "DELETE"}
                    />
                  </div>
                </React.Fragment>
              )}
            />
          </div>

          <div className="field">
            <Controller
              name="phone"
              control={control}
              rules={{
                required: t("forms.validation.required", {
                  entity: t("entities.hoppers.phone"),
                }),
                maxLength: {
                  value: 500,
                  message: t("forms.validation.maxLength", {
                    entity: t("entities.hoppers.phone"),
                    maxLength: 500,
                  }),
                },
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <div className="flex justify-content-between">
                    <label htmlFor="phone">{t("entities.hoppers.phone")}</label>
                    <div>{getFormErrorMessage(errors.phone)}</div>
                  </div>
                  <div className="form-crud">
                    <InputText
                      id="phone"
                      {...field}
                      value={field.value || ""}
                      maxLength={100}
                      className={[
                        "form-control",
                        classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                      ]}
                      disabled={settings.formMode === "DELETE"}
                    />
                  </div>
                </React.Fragment>
              )}
            />
          </div>
          <div>
            <Controller
              name="nick"
              control={control}
              rules={
                settings.formMode == "DELETE"
                  ? ""
                  : {
                      required: t("forms.validation.required", {
                        entity: t("entities.hoppers.nick"),
                      }),
                      maxLength: {
                        value: 30,
                        message: t("forms.validation.maxLength", {
                          entity: t("entities.hoppers.nick"),
                          maxLength: 30,
                        }),
                      },
                    }
              }
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <div className="flex justify-content-between">
                    <label htmlFor="nick">{t("entities.hoppers.nick")}</label>
                    <div>{getFormErrorMessage(errors.nick)}</div>
                  </div>
                  <div className="form-crud">
                    <InputText
                      id={field.nick}
                      {...field}
                      value={field.value || ""}
                      maxLength={100}
                      className={[
                        "form-control",
                        classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                      ]}
                      disabled={settings.formMode === "DELETE"}
                    />
                  </div>
                </React.Fragment>
              )}
            />
          </div>

          {/* <div className="field">
            <Controller
              name="email"
              control={control}
              rules={
                settings.formMode == "DELETE"
                  ? ""
                  : {
                      pattern: {
                        value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                        message: t("forms.validation.noValidEmail"),
                      },
                      maxLength: {
                        value: 60,
                        message: t("forms.validation.maxLength", {
                          entity: t("entities.hoppers.email"),
                          maxLength: 60,
                        }),
                      },
                    }
              }
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <div className="flex justify-content-between">
                    <label htmlFor="email">{t("entities.hoppers.email")}</label>
                    <div>{getFormErrorMessage(errors.email)}</div>
                  </div>
                  <div className="form-crud">
                    <InputText
                      id="email"
                      {...field}
                      value={field.value || ""}
                      maxLength={60}
                      className={[
                        "form-control",
                        classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                      ]}
                      autoComplete="off"
                      disabled={settings.formMode === "DELETE"}
                    />
                  </div>
                </React.Fragment>
              )}
            />
          </div> */}

          {isVisible && (
            <div className="field">
              <Controller
                name="password"
                control={control}
                rules={
                  settings.formMode == "DELETE"
                    ? ""
                    : {
                        required: t("forms.validation.required", {
                          entity: t("entities.hoppers.password"),
                        }),
                        maxLength: {
                          value: 30,
                          message: t("forms.validation.maxLength", {
                            entity: t("entities.hoppers.password"),
                            maxLength: 30,
                          }),
                        },
                      }
                }
                render={({ field, fieldState }) => (
                  <React.Fragment>
                    <div className="flex justify-content-between">
                      <label htmlFor="password">
                        {t("entities.hoppers.password")}
                      </label>
                      <div>{getFormErrorMessage(errors.password)}</div>
                    </div>
                    <div className="form-crud">
                      <Password
                        id="password"
                        {...field}
                        inputRef={field.ref}
                        className={classNames({
                          "p-invalid": fieldState.error,
                        })}
                        feedback={false}
                        disabled={settings.formMode === "DELETE"}
                        autoComplete="new-password"
                      />
                    </div>
                  </React.Fragment>
                )}
              />
            </div>
          )}

          {isEditMode && (
            <div>
              <Button
                style={{ boxShadow: "none" }}
                label={
                  isVisible
                    ? t("entities.hoppers.hidePassword")
                    : t("entities.hoppers.showPassword")
                }
                onClick={handleShow}
                link
              />
            </div>
          )}
          <div className="field">
            <Controller
              name="notes"
              control={control}
              rules={{
                maxLength: {
                  value: 500,
                  message: t("forms.validation.maxLength", {
                    entity: t("entities.hoppers.notes"),
                    maxLength: 500,
                  }),
                },
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <div className="flex justify-content-between">
                    <label htmlFor="notes">{t("entities.hoppers.notes")}</label>
                    <div>{getFormErrorMessage(errors.notes)}</div>
                  </div>
                  <div className="form-crud">
                    <InputTextarea
                      id="notes"
                      {...field}
                      value={field.value ? field.value : ""}
                      rows={3}
                      maxLength={500}
                      className={[
                        "form-control",
                        classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                      ]}
                      disabled={settings.formMode === "DELETE"}
                    />
                  </div>
                </React.Fragment>
              )}
            />
          </div>

          <div className="field">
            <div className="field-checkbox">
              <Controller
                name="active"
                control={control}
                render={({ field, fieldState }) => (
                  <Checkbox
                    inputId={field.name}
                    onChange={(e) => field.onChange(e.checked)}
                    checked={field.value}
                    className={classNames({ "p-invalid": fieldState.invalid })}
                    disabled={settings.formMode === "DELETE"}
                  />
                )}
              />
              <label
                htmlFor="accept"
                className={classNames({ "p-error": errors.active })}
              >
                {t("entities.hoppers.active")}
              </label>
            </div>
          </div>

          <div className="flex justify-content-between">
            <Button
              label={t("buttons.cancel")}
              icon="pi pi-times"
              className="p-button-text btn-secondary-crud"
              onClick={() => settings.hideDialog("CANCEL")}
              type="button"
              disabled={isLoading}
            />
            <Button
              label={
                isLoading
                  ? formModeConfig.SubmitButtonTextWait
                  : formModeConfig.SubmitButtonText
              }
              icon="pi pi-check"
              className=" btn btn-primary btn-primary-crud"
              type="submit"
              disabled={isLoading}
            />
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default HoppersForm;
