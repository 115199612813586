

const useSkeleton = (root) => {
    const skeleton = {
        "commons": {
            "dataTableContainer": { "values": "flex md:min-w-full flex-grow-1" }
        },
        "layout": {
            "values": "flex md:min-h-screen m-0",
            "menu": {
                "values": "flex flex-column md:min-h-full md:min-w-max border-solid border-1 border-600 bg-blue-500 text-white p-4",
                "appInfo": {
                    "appName": {
                        "values": "text-4xl"
                    },
                    "appVersion": {
                        "values": "text-xs"
                    }
                },
                "menuItems": {
                    "values": "flex flex-column mt-3",
                    "menuItem": {
                        "values": "flex mb-1",
                        "group": {
                            "values": "mb-2 mt-4 text-1xl opacity-70"
                        },
                        "option": {
                            "values": "flex mt-3",
                            "icon": {
                                "values": "pr-4 pt-1 w-1rem"
                            },
                            "text": {
                                "values": ""
                            }
                        }
                    }
                },
                "profile": {
                    "values": "flex flex-column flex-grow-1 justify-content-end"
                }
            },
            "content": {
                "values": "flex flex-column m-0 p-0 md:min-h-full md:w-full",
                "header": {
                    "values": "flex p-2 h-6rem align-items-center md:w-full border-solid border-1 border-600",
                    "icon": {
                        "values": "ml-4 mr-15 text-4xl"
                    },
                    "info": {
                        "values": "flex flex-column align-content-start pl-4",
                        "route": { "values": "pl-1 text-600" },
                        "title": { "values": "pl-1 text-4xl" }
                    },
                    "actions": {
                        "values": "flex flex-grow-1 justify-content-end align-content-center p-2",
                    }
                },
                "main": {
                    "values": "flex flex-grow-1 p-2 border-solid border-1 border-600"
                }
            }
        }
    };

    const getObject = (jsonItem, baseSkeleton) => {
        let result = baseSkeleton;

        const fields = jsonItem.toString().split('.');

        for (let i = 0; i < fields.length; i++) {
            result = result[fields[i]];
        }

        return result;
    };

    // Usar getObject para inicializar currentSkeleton
    let currentSkeleton = root ? getObject(root, skeleton) : skeleton;

    const get = (jsonItem) => {
        let result = currentSkeleton;

        if (jsonItem) {
            result = getObject(jsonItem, currentSkeleton);
        }
        result = result?.values ? result.values.replaceAll(",", " ") : "";

        return result;
    }

    return {
        get
    }
};

export default useSkeleton;
