import React from "react";

import useSkeleton from "../hooks/useSkeleton";
import Menu from "./menu";
import "primeflex/primeflex.css";
import "./layout.css";

const Layout = ({ header, children }) => {
  const s = useSkeleton("layout");

  return (
    <div className={s.get()}>
      <Menu />
      <div className={s.get("content")}>
        <header>{header}</header>
        <main className={s.get("content.main")}>{children}</main>
      </div>
    </div>
  );
};

export default Layout;
