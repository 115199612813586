import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import useApi from "../../../hooks/useApi";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

import "react-toastify/dist/ReactToastify.css";
import "../crud.css";
import Searcher from "../../../components/searcher/searcher";
import { Column } from "primereact/column";

const VouchersForm = ({ settings }) => {
  const { t } = useTranslation();
  const api = useApi();

  const isEditMode = settings.formMode == "UPDATE";
  const isDeleteMode = settings.formMode == "DELETE";

  const {
    trigger,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [selectedField, setSelectedField] = useState({
    name: "",
  });
  const [selectedPurchaser, setSelectedPurchaser] = useState({
    name: "",
  });
  const [selectedCrop, setSelectedCrop] = useState({
    name: "",
  });
  const [selectedDestination, setSelectedDestination] = useState({
    name: "",
  });
  const [selectedTrucker, setSelectedTrucker] = useState({
    name: "",
  });

  const [maxPaddocks, setMaxPaddocks] = useState(1);

  const formModeConfig = {};
  if (isEditMode) {
    formModeConfig.SubmitButtonText = t("buttons.update");
    formModeConfig.SubmitButtonTextWait = `${t("buttons.updating")}...`;
    formModeConfig.serverAction = async (data) => {
      await settings.CRUDService.updateEntity(data);
    };
  } else if (isDeleteMode) {
    formModeConfig.SubmitButtonText = t("buttons.cancel");
    formModeConfig.SubmitButtonTextWait = `${t("buttons.canceling")}...`;
    formModeConfig.serverAction = async () => {
      const responseVouchersCancel = await api.put(
        `/vouchers/cancel/${settings.entityId}`
      );
    };
  } else {
    formModeConfig.SubmitButtonText = t("buttons.create");
    formModeConfig.SubmitButtonTextWait = `${t("buttons.creating")}...`;
    formModeConfig.serverAction = async (data) => {
      const newVoucher = {
        field_Id: selectedField.id,
        field_Paddocks: data.field_Paddocks,
        purchaser_Id: selectedPurchaser.id,
        crop_Id: selectedCrop.id,
        destination_Id: selectedDestination.id,
        weight: data.weight,
        trucker_Id: selectedTrucker.id,
        truckPlate: data.truckPlate,
      };

      await settings.CRUDService.createEntity(newVoucher);
    };
  }

  useEffect(() => {
    if (selectedField) {
      setMaxPaddocks(selectedField.paddocks);
      setValue("paddocks", 0); // Esto reseteará el valor de paddocks a 0
    }
  }, [selectedField]);

  useEffect(() => {
    if (isEditMode || isDeleteMode) {
      const fetchVoucher = async () => {
        try {
          const data = await settings.CRUDService.getEntity(settings.entityId);

          for (const key in data) {
            setValue(key, data[key]);
          }
          setSelectedField({
            ...selectedField,
            id: data.field_Id,
            name: data.field_Name,
          });
          setSelectedPurchaser({
            ...selectedPurchaser,
            id: data.purchaser_Id,
            name: data.purchaser_Name,
          });
          setSelectedCrop({
            ...selectedCrop,
            id: data.crop_Id,
            name: data.crop_Name,
          });
          setSelectedDestination({
            ...selectedDestination,
            id: data.destination_Id,
            name: data.destination_Name,
          });
          setSelectedTrucker({
            ...selectedTrucker,
            id: data.trucker_Id,
            name: data.trucker_Name,
          });
        } catch (error) {
          console.error(t("forms.messages.operation.error"), error);
        }
      };

      fetchVoucher();
    }
  }, [settings.entityId, setValue, settings.formMode]);

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      await formModeConfig.serverAction(data);

      settings.hideDialog();

      settings.toast.current.show({
        severity: "success",
        summary: t("forms.messages.titles.success"),
        detail: t("forms.messages.operation.success"),
        life: 3000,
      });
    } catch (error) {
      let detail = t("forms.messages.operation.error");

      if (error.response.status == 409) {
        if (error.response.data.Message == "NO_OPEN_HARVEST") {
          detail = t("errors.vouchers.no_open_harvest");
        }
      }

      settings.toast.current.show({
        severity: "error",
        summary: t("forms.messages.titles.error"),
        detail: detail,
        life: 3000,
      });

      setIsLoading(false);
    }
  };

  const getFormErrorMessage = (error) => {
    return error && <small className="p-error">{error.message}</small>;
  };

  useEffect(() => {
    if (selectedField && selectedField.name) {
      setValue("field", selectedField.name); // Usa setValue para actualizar el valor en react-hook-form
      trigger("field"); // Trigger la validación
    }
  }, [selectedField, setValue, trigger]);
  useEffect(() => {
    if (selectedPurchaser && selectedPurchaser.name) {
      setValue("purchaser", selectedPurchaser.name);
      trigger("purchaser");
    }
  }, [selectedPurchaser, setValue, trigger]);
  useEffect(() => {
    if (selectedCrop && selectedCrop.name) {
      setValue("crop", selectedCrop.name);
      trigger("crop");
    }
  }, [selectedCrop, setValue, trigger]);
  useEffect(() => {
    if (selectedDestination && selectedDestination.name) {
      setValue("destination", selectedDestination.name);
      trigger("destination");
    }
  }, [selectedDestination, setValue, trigger]);
  useEffect(() => {
    if (selectedTrucker && selectedTrucker.name) {
      setValue("trucker", selectedTrucker.name); // Usa setValue para actualizar el valor en react-hook-form
      trigger("trucker"); // Trigger la validación
    }
  }, [selectedTrucker, setValue, trigger]);

  return (
    <React.Fragment>
      <div className="flex justify-content-center">
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <div className="field">
            <Controller
              name="field"
              control={control}
              rules={{
                required: t("forms.validation.required", {
                  entity: t("entities.fields.singular"),
                }),
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <div className="flex justify-content-between">
                    <label htmlFor="field">
                      {t("entities.fields.singular")}
                    </label>
                    <div>{getFormErrorMessage(errors.field)}</div>
                  </div>
                  <div className="form-crud">
                    <div className="p-inputgroup">
                      <span className="p-float-label">
                        <InputText
                          id="field"
                          {...field}
                          value={selectedField.name || ""}
                          className={[
                            "form-control",
                            classNames({
                              "p-invalid": fieldState.invalid,
                            }),
                          ]}
                          disabled={settings.formMode === "DELETE"}
                        />
                      </span>
                      <Searcher
                        settings={{
                          columns: JSON.stringify([
                            {
                              name: "name",
                              type: "string",
                            },
                          ]),
                          entityName: "fields",
                          fieldName: "fields",
                          selectedEntity: selectedField,
                          setSelectedEntity: setSelectedField,
                          defaultSortField: "name",
                          disabled: settings.formMode === "DELETE",
                          tableColumns: [
                            <Column
                              field="name"
                              header={t("entities.fields.name")}
                              sortable
                              key="id"
                            ></Column>,
                            <Column
                              field="notes"
                              header={t("entities.fields.notes")}
                              sortable
                              key="id"
                            ></Column>,
                          ],
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            />
          </div>

          <div className="field">
            <Controller
              name="field_Paddocks"
              control={control}
              rules={{
                required: t("forms.validation.required", {
                  entity: t("entities.fields.paddocks"),
                }),
                min: {
                  value: 1,
                  message: t("forms.validation.min", {
                    entity: t("entities.fields.paddocks"),
                    min: 1,
                  }),
                },
                max: {
                  value: { maxPaddocks },
                  message: t("forms.validation.max", {
                    entity: t("entities.fields.paddocks"),
                    max: { maxPaddocks },
                  }),
                },
              }}
              render={({ field }) => (
                <React.Fragment>
                  <div className="flex justify-content-between">
                    <label htmlFor="field_Paddocks">
                      {t("entities.fields.paddocks")}
                    </label>
                    <div>{getFormErrorMessage(errors.field_Paddocks)}</div>
                  </div>
                  <div className="form-crud">
                    <InputNumber
                      id={field.name}
                      value={field.value || 0}
                      onValueChange={(e) => field.onChange(e.value)}
                      mode="decimal"
                      showButtons
                      min={0}
                      max={maxPaddocks}
                      inputClassName={[
                        classNames({ "p-invalid": errors.field_Paddocks }),
                      ]}
                      disabled={settings.formMode === "DELETE"}
                    />
                  </div>
                </React.Fragment>
              )}
            />
          </div>

          <div className="field">
            <Controller
              name="purchaser"
              control={control}
              rules={{
                required: t("forms.validation.required", {
                  entity: t("entities.purchasers.singular"),
                }),
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <div className="flex justify-content-between">
                    <label htmlFor="field">
                      {t("entities.purchasers.singular")}
                    </label>
                    <div>{getFormErrorMessage(errors.purchaser)}</div>
                  </div>
                  <div className="form-crud">
                    <div className="p-inputgroup">
                      <span className="p-float-label">
                        <InputText
                          id="purchasers"
                          {...field}
                          value={selectedPurchaser.name || ""}
                          className={[
                            "form-control",
                            classNames({
                              "p-invalid": fieldState.invalid,
                            }),
                          ]}
                          disabled={settings.formMode === "DELETE"}
                        />
                      </span>
                      <Searcher
                        settings={{
                          columns: JSON.stringify([
                            {
                              name: "name",
                              type: "string",
                            },
                            {
                              name: "emails",
                              type: "string",
                            },
                          ]),
                          entityName: "purchasers",
                          fieldName: "purchasers",
                          selectedEntity: selectedPurchaser,
                          setSelectedEntity: setSelectedPurchaser,
                          defaultSortField: "name",
                          disabled: settings.formMode === "DELETE",
                          tableColumns: [
                            <Column
                              field="name"
                              header={t("entities.purchasers.name")}
                              sortable
                              key="id"
                            ></Column>,
                            <Column
                              field="address"
                              header={t("entities.purchasers.address")}
                              sortable
                              key="id"
                            ></Column>,
                            <Column
                              field="phone"
                              header={t("entities.purchasers.phone")}
                              sortable
                              key="id"
                            ></Column>,
                            <Column
                              field="notes"
                              header={t("entities.fields.notes")}
                              sortable
                              key="id"
                            ></Column>,
                          ],
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            />
          </div>

          <div className="field">
            <Controller
              name="crop"
              control={control}
              rules={{
                required: t("forms.validation.required", {
                  entity: t("entities.crops.singular"),
                }),
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <div className="flex justify-content-between">
                    <label htmlFor="crop">{t("entities.crops.singular")}</label>
                    <div>{getFormErrorMessage(errors.crop)}</div>
                  </div>
                  <div className="form-crud">
                    <div className="p-inputgroup">
                      <span className="p-float-label">
                        <InputText
                          id="crops"
                          {...field}
                          value={selectedCrop.name || ""}
                          className={[
                            "form-control",
                            classNames({
                              "p-invalid": fieldState.invalid,
                            }),
                          ]}
                          disabled={settings.formMode === "DELETE"}
                        />
                      </span>
                      <Searcher
                        settings={{
                          columns: JSON.stringify([
                            {
                              name: "name",
                              type: "string",
                            },
                          ]),
                          entityName: "crops",
                          fieldName: "crops",
                          selectedEntity: selectedCrop,
                          setSelectedEntity: setSelectedCrop,
                          defaultSortField: "name",
                          disabled: settings.formMode === "DELETE",
                          tableColumns: [
                            <Column
                              field="name"
                              header={t("entities.crops.name")}
                              sortable
                              key="id"
                            ></Column>,
                            <Column
                              field="notes"
                              header={t("entities.crops.notes")}
                              sortable
                              key="id"
                            ></Column>,
                          ],
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            />
          </div>

          <div className="field">
            <Controller
              name="destination"
              control={control}
              rules={{
                required: t("forms.validation.required", {
                  entity: t("entities.destinations.singular"),
                }),
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <div className="flex justify-content-between">
                    <label htmlFor="crop">
                      {t("entities.destinations.singular")}
                    </label>
                    <div>{getFormErrorMessage(errors.destination)}</div>
                  </div>
                  <div className="form-crud">
                    <div className="p-inputgroup">
                      <span className="p-float-label">
                        <InputText
                          id="destinations"
                          {...field}
                          value={selectedDestination.name || ""}
                          className={[
                            "form-control",
                            classNames({
                              "p-invalid": fieldState.invalid,
                            }),
                          ]}
                          disabled={settings.formMode === "DELETE"}
                        />
                      </span>
                      <Searcher
                        settings={{
                          columns: JSON.stringify([
                            {
                              name: "name",
                              type: "string",
                            },
                          ]),
                          entityName: "destinations",
                          fieldName: "destinations",
                          selectedEntity: selectedDestination,
                          setSelectedEntity: setSelectedDestination,
                          defaultSortField: "name",
                          disabled: settings.formMode === "DELETE",
                          tableColumns: [
                            <Column
                              field="name"
                              header={t("entities.destinations.name")}
                              sortable
                              key="id"
                            ></Column>,
                            <Column
                              field="notes"
                              header={t("entities.destinations.notes")}
                              sortable
                              key="id"
                            ></Column>,
                          ],
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            />
          </div>

          <div className="field">
            <Controller
              name="weight"
              control={control}
              rules={{
                required: t("forms.validation.required", {
                  entity: t("entities.vouchers.weight"),
                }),
                min: {
                  value: 1,
                  message: t("forms.validation.min", {
                    entity: t("entities.vouchers.weight"),
                    min: 1,
                  }),
                },
                max: {
                  value: 100000,
                  message: t("forms.validation.max", {
                    entity: t("entities.vouchers.weight"),
                    max: 100000,
                  }),
                },
              }}
              render={({ field }) => (
                <React.Fragment>
                  <div className="flex justify-content-between">
                    <label htmlFor="weight">
                      {t("entities.vouchers.weight")}
                    </label>
                    <div>{getFormErrorMessage(errors.weight)}</div>
                  </div>
                  <div className="form-crud">
                    <InputNumber
                      id={field.name}
                      value={field.value || 0}
                      onValueChange={(e) => field.onChange(e.value)}
                      mode="decimal"
                      showButtons
                      min={0}
                      max={100000}
                      inputClassName={[
                        classNames({ "p-invalid": errors.weight }),
                      ]}
                      disabled={settings.formMode === "DELETE"}
                    />
                  </div>
                </React.Fragment>
              )}
            />
          </div>

          <div className="field">
            <Controller
              name="trucker"
              control={control}
              rules={{
                required: t("forms.validation.required", {
                  entity: t("entities.truckers.singular"),
                }),
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <div className="flex justify-content-between">
                    <label htmlFor="crop">
                      {t("entities.truckers.singular")}
                    </label>
                    <div>{getFormErrorMessage(errors.trucker)}</div>
                  </div>
                  <div className="form-crud">
                    <div className="p-inputgroup">
                      <span className="p-float-label">
                        <InputText
                          id="truckers"
                          {...field}
                          value={selectedTrucker.name || ""}
                          className={[
                            "form-control",
                            classNames({
                              "p-invalid": fieldState.invalid,
                            }),
                          ]}
                          disabled={settings.formMode === "DELETE"}
                        />
                      </span>
                      <Searcher
                        settings={{
                          columns: JSON.stringify([
                            {
                              name: "name",
                              type: "string",
                            },
                            {
                              name: "phone",
                              type: "string",
                            },
                          ]),
                          entityName: "truckers",
                          fieldName: "truckers",
                          selectedEntity: selectedTrucker,
                          setSelectedEntity: setSelectedTrucker,
                          defaultSortField: "name",
                          disabled: settings.formMode === "DELETE",
                          tableColumns: [
                            <Column
                              field="name"
                              header={t("entities.truckers.name")}
                              sortable
                              key="id"
                            ></Column>,
                            <Column
                              field="phone"
                              header={t("entities.truckers.phone")}
                              sortable
                              key="id"
                            ></Column>,
                            <Column
                              field="notes"
                              header={t("entities.truckers.notes")}
                              sortable
                              key="id"
                            ></Column>,
                          ],
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            />
          </div>

          <div className="field">
            <Controller
              name="truckPlate"
              control={control}
              rules={{
                required: t("forms.validation.required", {
                  entity: t("entities.vouchers.truckPlate"),
                }),
                maxLength: {
                  value: 10,
                  message: t("forms.validation.maxLength", {
                    entity: t("entities.vouchers.truckPlate"),
                    maxLength: 10,
                  }),
                },
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <div className="flex justify-content-between">
                    <label htmlFor="truckPlate">
                      {t("entities.vouchers.truckPlate")}
                    </label>
                    <div>{getFormErrorMessage(errors.truckPlate)}</div>
                  </div>
                  <div className="form-crud">
                    <InputText
                      id="truckPlate"
                      {...field}
                      value={field.value || ""}
                      maxLength={10}
                      className={[
                        "form-control",
                        classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                      ]}
                      disabled={settings.formMode === "DELETE"}
                    />
                  </div>
                </React.Fragment>
              )}
            />
          </div>

          <div className="flex justify-content-between">
            <Button
              label={t("buttons.cancel")}
              icon="pi pi-times"
              className="p-button-text btn-secondary-crud"
              onClick={() => settings.hideDialog("CANCEL")}
              type="button"
              disabled={isLoading}
            />
            <Button
              label={
                isLoading
                  ? formModeConfig.SubmitButtonTextWait
                  : formModeConfig.SubmitButtonText
              }
              icon="pi pi-check"
              className=" btn btn-primary btn-primary-crud"
              type="submit"
              disabled={isLoading}
            />
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default VouchersForm;
