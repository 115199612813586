import React from "react";
import { useTranslation } from "react-i18next";
import jwtDecode from "jwt-decode";

import MenuItem from "./menuItem";
import { useNavigate } from "react-router-dom";
import useSkeleton from "../hooks/useSkeleton";

import fontawesome from "../assets/fonts/assets/css/fontawesome.min.css";
import solid from "../assets/fonts/assets/css/solid.min.css";
import brands from "../assets/fonts/assets/css/brands.min.css";

const Menu = () => {
  const skeleton = useSkeleton("layout.menu");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const userName = localStorage.getItem("userName");

  return (
    <div className={skeleton.get()}>
      <div className={skeleton.get("appInfo")}>
        <div className={skeleton.get("appInfo.appName")}>Remitos</div>
        <div className={skeleton.get("appInfo.appVersion")}>Versión 1.01</div>
      </div>
      <div className={skeleton.get("menuItems")}>
        <MenuItem
          icon={<i className="fa-solid fa-calendar-week"></i>}
          to="/harvests"
          label={t("entities.harvests.plural")}
        ></MenuItem>
        <div className={skeleton.get("menuItems.menuItem.group")}>Remitos</div>
        <MenuItem
          icon={<i className="fa-solid fa-file-lines"></i>}
          to="/vouchersactives"
          label={t("entities.vouchers.actives")}
        ></MenuItem>
        <MenuItem
          icon={<i className="fa-solid fa-layer-group"></i>}
          to="/vouchershistory"
          label={t("entities.vouchers.history")}
        ></MenuItem>
        <div className={skeleton.get("menuItems.menuItem.group")}>
          Configuración
        </div>
        <MenuItem
          icon={<i className="fa-solid fa-sun-plant-wilt"></i>}
          to="/configurations/fields"
          label={t("entities.fields.plural")}
        ></MenuItem>
        <MenuItem
          icon={<i className="fa-solid fa-wheat-awn"></i>}
          to="/configurations/crops"
          label={t("entities.crops.plural")}
        ></MenuItem>
        <MenuItem
          icon={<i className="fa-solid fa-flag-checkered"></i>}
          to="/configurations/destinations"
          label={t("entities.destinations.plural")}
        ></MenuItem>
        <MenuItem
          icon={<i className="fa-solid fa-globe"></i>}
          to="/configurations/logisticsProviders"
          label={t("entities.logisticsProviders.plural")}
        ></MenuItem>
        <MenuItem
          icon={<i className="fa-solid fa-money-bill-wheat"></i>}
          to="/configurations/purchasers"
          label={t("entities.purchasers.plural")}
        ></MenuItem>
        <div className={skeleton.get("menuItems.menuItem.group")}>Usuarios</div>
        <MenuItem
          icon={<i className="fa-solid fa-truck"></i>}
          to="/users/truckers"
          label={t("entities.truckers.plural")}
        ></MenuItem>
        <MenuItem
          icon={<i className="fa-solid fa-filter"></i>}
          to="/users/hoppers"
          label={t("entities.hoppers.plural")}
        ></MenuItem>
        <MenuItem
          icon={<i className="fa-solid fa-user-gear"></i>}
          to="/users/administrators"
          label={t("entities.administrators.plural")}
        ></MenuItem>
      </div>
      <div className={skeleton.get("menuItems.menuItem.group")}>{userName}</div>
      <MenuItem
        icon={<i className="fa-regular fa-user"></i>}
        to="/client/client"
        label={t("profile")}
      ></MenuItem>
      <MenuItem
        icon={<i className="fa-solid fa-arrow-right-from-bracket"></i>}
        to="/logout"
        label={t("Logout")}
      ></MenuItem>
    </div>
  );
};

export default Menu;
