import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";

import LocationPicker from "../../../../components/LocationPicker/LocationPicker";

import "react-toastify/dist/ReactToastify.css";

import useApi from "../../../../hooks/useApi";

import "../../crud.css";

const FieldsForm = ({ settings }) => {
  const { t } = useTranslation();

  const [showMap, setShowMap] = useState(false);

  const isEditMode = settings.formMode === "UPDATE";
  const isDeleteMode = settings.formMode === "DELETE";

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const defaultFieldData = {
    name: "",
    paddocks: 0,
    latitude: 0,
    longitude: 0,
    notes: "",
    active: false,
    clientId: 0,
  };

  const [isLoading, setIsLoading] = useState(false);
  const api = useApi();

  const formModeConfig = {};

  if (isEditMode) {
    formModeConfig.SubmitButtonText = t("buttons.update");
    formModeConfig.SubmitButtonTextWait = `${t("buttons.updating")}...`;
    formModeConfig.serverAction = async (data) => {
      await settings.CRUDService.updateEntity(data);
    };
  } else if (isDeleteMode) {
    formModeConfig.SubmitButtonText = t("buttons.delete");
    formModeConfig.SubmitButtonTextWait = `${t("buttons.deleting")}...`;
    formModeConfig.serverAction = async () => {
      await settings.CRUDService.deleteEntity(settings.entityId);
    };
  } else {
    formModeConfig.SubmitButtonText = t("buttons.create");
    formModeConfig.SubmitButtonTextWait = `${t("buttons.creating")}...`;
    formModeConfig.serverAction = async (data) => {
      await settings.CRUDService.createEntity(data);
    };
  }

  useEffect(() => {
    if (isEditMode || isDeleteMode) {
      const fetchField = async () => {
        try {
          const data = await settings.CRUDService.getEntity(settings.entityId);

          // Merge the received data with defaultFieldData, to ensure all fields are defined.
          const fieldData = { ...defaultFieldData, ...data };

          for (const key in fieldData) {
            setValue(key, fieldData[key]);
          }
        } catch (error) {
          console.error(t("forms.messages.operation.error"), error);
        }
      };

      fetchField();
    }
  }, [settings.entityId, setValue, api, settings.formMode]);

  const onSubmit = async (data) => {
    setIsLoading(true);

    try {
      await formModeConfig.serverAction(data);

      settings.hideDialog();

      settings.toast.current.show({
        severity: "success",
        summary: t("forms.messages.titles.success"),
        detail: t("forms.messages.operation.success"),
        life: 3000,
      });
    } catch (error) {
      settings.toast.current.show({
        severity: "error",
        summary: t("forms.messages.titles.error"),
        detail: t("forms.messages.operation.error"),
        life: 3000,
      });

      setIsLoading(false);
    }
  };

  const getFormErrorMessage = (error) => {
    return error && <small className="p-error">{error.message}</small>;
  };

  const hideMap = () => {
    setShowMap(false);
  };

  const getLocation = ({ lat, lng }) => {
    setValue("latitude", lat);
    setValue("longitude", lng);
  };

  return (
    <React.Fragment>
      <div className="flex justify-content-center">
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <div className="field">
            <Controller
              name="name"
              control={control}
              defaultValue={defaultFieldData.name}
              rules={{
                required: t("forms.validation.required", {
                  entity: t("entities.fields.name"),
                }),
                maxLength: {
                  value: 500,
                  message: t("forms.validation.maxLength", {
                    entity: t("entities.fields.name"),
                    maxLength: 500,
                  }),
                },
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <div className="flex justify-content-between">
                    <label htmlFor="name">{t("entities.fields.name")}</label>
                    <div>{getFormErrorMessage(errors.name)}</div>
                  </div>
                  <div className="form-crud">
                    <InputText
                      id="name"
                      {...field}
                      value={field.value || ""}
                      maxLength={100}
                      className={[
                        "form-control",
                        classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                      ]}
                      disabled={settings.formMode === "DELETE"}
                    />
                  </div>
                </React.Fragment>
              )}
            />
          </div>

          <div className="field">
            <Controller
              name="paddocks"
              control={control}
              defaultValue={defaultFieldData.paddocks}
              rules={{
                required: t("forms.validation.required", {
                  entity: t("entities.fields.paddocks"),
                }),
                min: {
                  value: 1,
                  message: t("forms.validation.min", {
                    entity: t("entities.fields.paddocks"),
                    min: 1,
                  }),
                },
                max: {
                  value: 10,
                  message: t("forms.validation.max", {
                    entity: t("entities.fields.paddocks"),
                    max: 10,
                  }),
                },
              }}
              render={({ field }) => (
                <React.Fragment>
                  <div className="flex justify-content-between">
                    <label htmlFor="paddocks">
                      {t("entities.fields.paddocks")}
                    </label>
                    <div>{getFormErrorMessage(errors.paddocks)}</div>
                  </div>
                  <div className="form-crud">
                    <InputNumber
                      id={field.name}
                      value={field.value || 0}
                      onValueChange={(e) => field.onChange(e.value)}
                      mode="decimal"
                      showButtons
                      min={0}
                      max={10}
                      inputClassName={[
                        classNames({ "p-invalid": errors.paddocks }),
                      ]}
                      disabled={settings.formMode === "DELETE"}
                    />
                  </div>
                </React.Fragment>
              )}
            />
          </div>

          {!showMap && (
            <div className="field">
              <Controller
                name="latitude"
                control={control}
                defaultValue={defaultFieldData.latitude}
                rules={{
                  required: t("forms.validation.required", {
                    entity: t("entities.fields.latitude"),
                  }),
                  min: {
                    value: -90,
                    message: t("forms.validation.min", {
                      entity: t("entities.fields.latitude"),
                      min: -90,
                    }),
                  },
                  max: {
                    value: 90,
                    message: t("forms.validation.max", {
                      entity: t("entities.fields.latitude"),
                      max: 90,
                    }),
                  },
                }}
                render={({ field }) => (
                  <React.Fragment>
                    <div className="flex justify-content-between">
                      <label htmlFor="paddocks">
                        {t("entities.fields.latitude")}
                      </label>
                      <div>{getFormErrorMessage(errors.latitude)}</div>
                    </div>
                    <div className="form-crud">
                      <InputNumber
                        onClick={() => {
                          setShowMap(true);
                        }}
                        id={field.name}
                        value={field.value || 0}
                        onValueChange={(e) => field.onChange(e.value)}
                        mode="decimal"
                        locale="de-DE"
                        minFractionDigits={2}
                        min={-90}
                        max={90}
                        inputClassName={[
                          classNames({ "p-invalid": errors.latitude }),
                        ]}
                        disabled={settings.formMode === "DELETE"}
                      />
                    </div>
                  </React.Fragment>
                )}
              />
            </div>
          )}

          {!showMap && (
            <div className="field">
              <Controller
                name="longitude"
                control={control}
                defaultValue={defaultFieldData.longitude}
                rules={{
                  required: t("forms.validation.required", {
                    entity: t("entities.fields.longitude"),
                  }),
                  min: {
                    value: -180,
                    message: t("forms.validation.min", {
                      entity: t("entities.fields.longitude"),
                      min: -180,
                    }),
                  },
                  max: {
                    value: 180,
                    message: t("forms.validation.max", {
                      entity: t("entities.fields.longitude"),
                      max: 180,
                    }),
                  },
                }}
                render={({ field }) => (
                  <React.Fragment>
                    <div className="flex justify-content-between">
                      <label htmlFor="longitude">
                        {t("entities.fields.longitude")}
                      </label>
                      <div>{getFormErrorMessage(errors.longitude)}</div>
                    </div>
                    <div className="form-crud">
                      <InputNumber
                        onClick={() => {
                          setShowMap(true);
                        }}
                        id={field.name}
                        value={field.value || 0}
                        onValueChange={(e) => field.onChange(e.value)}
                        mode="decimal"
                        locale="de-DE"
                        minFractionDigits={2}
                        min={-180}
                        max={180}
                        inputClassName={[
                          classNames({ "p-invalid": errors.longitude }),
                        ]}
                        disabled={settings.formMode === "DELETE"}
                      />
                    </div>
                  </React.Fragment>
                )}
              />
            </div>
          )}

          {showMap && (
            <div className="field">
              <LocationPicker hideMap={hideMap} getLocation={getLocation} />
            </div>
          )}

          <div className="field">
            <Controller
              name="notes"
              control={control}
              defaultValue={defaultFieldData.notes}
              rules={{
                maxLength: {
                  value: 500,
                  message: t("forms.validation.maxLength", {
                    entity: t("entities.fields.notes"),
                    maxLength: 500,
                  }),
                },
              }}
              render={({ field, fieldState }) => (
                <React.Fragment>
                  <div className="flex justify-content-between">
                    <label htmlFor="notes">{t("entities.fields.notes")}</label>
                    <div>{getFormErrorMessage(errors.notes)}</div>
                  </div>
                  <div className="form-crud">
                    <InputTextarea
                      id="notes"
                      {...field}
                      value={field.value ? field.value : ""}
                      rows={3}
                      maxLength={500}
                      className={[
                        "form-control",
                        classNames({
                          "p-invalid": fieldState.invalid,
                        }),
                      ]}
                      disabled={settings.formMode === "DELETE"}
                    />
                  </div>
                </React.Fragment>
              )}
            />
          </div>

          <div className="field">
            <div className="field-checkbox">
              <Controller
                name="active"
                control={control}
                defaultValue={defaultFieldData.active}
                render={({ field, fieldState }) => (
                  <Checkbox
                    inputId={field.name}
                    onChange={(e) => field.onChange(e.checked)}
                    checked={field.value}
                    className={classNames({ "p-invalid": fieldState.invalid })}
                    disabled={settings.formMode === "DELETE"}
                  />
                )}
              />
              <label
                htmlFor="accept"
                className={classNames({ "p-error": errors.active })}
              >
                {t("Active")}
              </label>
            </div>
          </div>

          <div className="flex justify-content-between">
            <Button
              label={t("buttons.cancel")}
              icon="pi pi-times"
              className="p-button-text btn-secondary-crud"
              onClick={() => settings.hideDialog("CANCEL")}
              type="button"
              disabled={isLoading}
            />
            <Button
              label={
                isLoading
                  ? formModeConfig.SubmitButtonTextWait
                  : formModeConfig.SubmitButtonText
              }
              icon="pi pi-check"
              className=" btn btn-primary btn-primary-crud"
              type="submit"
              disabled={isLoading}
            />
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

export default FieldsForm;
